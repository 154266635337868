<script setup>
import { PostType } from '@/enums';
import { useReaction } from '@/utils/reaction';
import { getFriendlyTime } from '@/utils';

import Actions from '@/components/Post/Actions.vue';
import IconComment from '@/components/Icons/Comment.vue';
import IconThumbsDown from '@/components/Icons/ThumbsDown.vue';
import IconThumbsDownFilled from '@/components/Icons/ThumbsDownFilled.vue';
import IconThumbsUp from '@/components/Icons/ThumbsUp.vue';
import IconThumbsUpFilled from '@/components/Icons/ThumbsUpFilled.vue';
import PostLink from '@/components/Link/PostLink.vue';

const props = defineProps({
    post: {
        required: true,
        type: Object,
    },
});

defineEmits(['deleted']);

const reaction = useReaction(props.post.id, 'post', {
    total_likes: props.post.positive_reactions_count,
    user_reaction: props.post.user_reaction ? props.post.user_reaction.type : null,
});
</script>

<template>
    <div>
        <div class="flex items-center gap-1 font-medium">
            <div v-if="post.last_edited_at" class="text-primary font-semibold text-sm">
                Edited at <time :datetime="post.last_edited_at">{{ getFriendlyTime(post.last_edited_at, true) }}</time>
            </div>
            <div class="grow"></div>
            <div class="flex gap-1 opacity-60 text-xs">
                <PostLink :post="post"><span>{{ post.comments_count_abbrv }} {{ post.comments_count > 1 ? (post.type === PostType.QUESTION ? 'Answers' : 'Comments') : (post.type === PostType.QUESTION ? 'Answer' : 'Comment') }}</span></PostLink>
                <span>{{ reaction.total_likes }} {{ reaction.total_likes > 1 ? "Upvotes" : "Upvote"}}</span>
            </div>
        </div>
        <div class="pt-2 mt-2 border-t border-white-foreground/10">
            <div class="flex items-center justify-between self-stretch text-white-foreground/60 font-medium">
                <PostLink
                    :post="post"
                    class="flex py-2 justify-center items-center gap-2 cursor-pointer">
                    <IconComment width="20" height="20" />
                    <span class="text-sm md:text-sm font-medium">{{ post.type === PostType.QUESTION ? 'Answer' : 'Comment' }}</span>
                </PostLink>
                <button type="button" class="flex py-2 justify-center items-center gap-2" @click="reaction.like">
                    <component
                        :is="reaction.liked ? IconThumbsUpFilled: IconThumbsUp"
                        :class="{'text-[#F24E1E]': reaction.liked}"
                        width="20" height="20" />
                    <span class="text-sm md:text-sm font-medium">Upvote</span>
                </button>
                <button type="button" class="flex py-2 justify-center items-center gap-2" @click="reaction.dislike">
                    <component
                        :is="reaction.disliked ? IconThumbsDownFilled: IconThumbsDown"
                        :class="{'text-[#F24E1E]': reaction.disliked}"
                        width="20" height="20" />
                    <span class="text-sm md:text-sm font-medium">Downvote</span>
                </button>
                <Actions :post="post" @deleted="$emit('deleted')" />
            </div>
        </div>
    </div>
</template>

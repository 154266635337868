<script setup>
const selected = defineModel();
const props = defineProps({
    options: {
        required: true,
        type: Object,
    },
    placeholder: {
        required: false,
        type: String,
        default: null,
    },
    required: {
        required: false,
        type: Boolean,
        default: false,
    },
    compact: {
        required: false,
        type: Boolean,
        default: false,
    }
});

const placeholder_attrs = {};

if (props.required) {
    placeholder_attrs.disabled = true;
}
</script>

<template>
    <select
        v-model="selected"
        class="
        block w-full rounded-lg focus:outline-none
        placeholder:text-black/40
        border-0 ring-1 ring-inset ring-black/20
        focus:ring-2 focus:ring-black/30
        invalid:focus:ring-rose-200 invalid:focus:border-rose-600
        group-invalid:focus:ring-rose-200 group-invalid:focus:border-rose-600
        text-slate-500 bg-white text-white-foreground"
        :class="{
            'py-1 pl-2': compact,
            'text-white-foreground text-opacity-30': !selected,
        }"
        >
        <option v-if="placeholder" :value="null" v-bind="placeholder_attrs">{{  placeholder }}</option>
        <option v-for="value, key in options" :key="key" :value="key">{{ value }}</option>
    </select>
</template>

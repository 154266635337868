<script setup>
import { computed, onMounted, ref } from 'vue';

const props = defineProps({
    lines: {
        type: [Number, String],
        default: 5,
    },
});

const clampElement = ref(null);
const clampable = ref(false);
const clamped = ref(true);

const clampStyle = computed(() => {
    if (!clamped.value) {
        return {};
    }

    return {
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': props.lines,
    };;
});

onMounted(() => {
    clampable.value = clampElement.value.scrollHeight > clampElement.value.clientHeight;
})

</script>

<template>
    <div>
        <div :class="{
            'cursor-pointer' : clampable && clamped,
        }" ref="clampElement" :style="clampStyle" @click="() => {
            if (clampable && clamped) {
                clamped = false;
            }
        }">
            <slot></slot>
        </div>
        <button v-if="clampable" class="block w-full text-left" type="button" @click="clamped = !clamped">
            <span class="text-primary font-medium text-sm">
                {{ clamped ? 'Read more' : 'Read less' }}
            </span>
        </button>
    </div>
</template>

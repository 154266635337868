import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

class EchoNotSupportedPresenceChannel {
    /**
     * Register a callback to be called anytime the member list changes.
     */
    here(callback) {
        return this;
    }
    /**
     * Listen for someone joining the channel.
     */
    joining(callback) {
        return this;
    }
    /**
     * Send a whisper event to other clients in the channel.
     */
    whisper(eventName, data) {
        return this;
    }
    /**
     * Listen for someone leaving the channel.
     */
    leaving(callback) {
        return this;
    }
}

class EchoNotSupportedChannel {
    /**
     * Listen for an event on the channel instance.
     */
    listen(event, callback) {
        return this;
    }

    /**
     * Listen for a whisper event on the channel instance.
     */
    listenForWhisper(event, callback) {
        return this;
    }

    /**
     * Listen for an event on the channel instance.
     */
    notification(callback) {
        return this;
    }

    /**
     * Stop listening to an event on the channel instance.
     */
    stopListening(event, callback) {
        return this;
    }

    /**
     * Stop listening for a whisper event on the channel instance.
     */
    stopListeningForWhisper(event, callback) {
        return this;
    }

    /**
     * Register a callback to be called anytime a subscription succeeds.
     */
    subscribed(callback) {
        return this;
    }

    /**
     * Register a callback to be called anytime an error occurs.
     */
    error(callback) {
        return this;
    }
}

class EchoNotSupported {
    constructor() {
        //
    }

    /**
     * Get a channel instance by name.
     */
    channel(channel) {
        return new EchoNotSupportedChannel();
    }
    /**
     * Create a new connection.
     */
    connect() {
        //
    }
    /**
     * Disconnect from the Echo server.
     */
    disconnect() {
        //
    }
    /**
     * Get a presence channel instance by name.
     */
    join(channel) {
        return new EchoNotSupportedPresenceChannel();
    }
    /**
     * Leave the given channel, as well as its private and presence variants.
     */
    leave(channel) {
        //
    }
    /**
     * Leave the given channel.
     */
    leaveChannel(channel) {
        //
    }
    /**
     * Leave all channels.
     */
    leaveAllChannels() {
        //
    }
    /**
     * Listen for an event on a channel instance.
     */
    listen(channel, event, callback) {
        return new EchoNotSupportedChannel();
    }
    /**
     * Get a private channel instance by name.
     */
    private(channel) {
        return new EchoNotSupportedChannel();
    }
    /**
     * Get a private encrypted channel instance by name.
     */
    encryptedPrivate(channel) {
        return new EchoNotSupportedChannel();
    }
    /**
     * Get the Socket ID for the connection.
     */
    socketId() {
        return '';
    }
    /**
     * Register 3rd party request interceptiors. These are used to automatically
     * send a connections socket id to a Laravel app with a X-Socket-Id header.
     */
    registerInterceptors() {
        //
    }
    /**
     * Register a Vue HTTP interceptor to add the X-Socket-ID header.
     */
    registerVueRequestInterceptor() {
        //
    }
    /**
     * Register an Axios HTTP interceptor to add the X-Socket-ID header.
     */
    registerAxiosRequestInterceptor() {
        //
    }
    /**
     * Register jQuery AjaxPrefilter to add the X-Socket-ID header.
     */
    registerjQueryAjaxSetup() {
        //
    }
    /**
     * Register the Turbo Request interceptor to add the X-Socket-ID header.
     */
    registerTurboRequestInterceptor() {
        //
    }
}

const echo = typeof window === 'undefined' ? new EchoNotSupported() : new Echo({
    broadcaster: 'reverb',
    key: import.meta.env.VITE_REVERB_APP_KEY,
    wsHost: import.meta.env.VITE_REVERB_HOST,
    wsPort: import.meta.env.VITE_REVERB_PORT,
    wssPort: import.meta.env.VITE_REVERB_PORT,
    forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? 'https') === 'https',
});

export default echo;

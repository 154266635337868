export function elPositionFix ({ el, style = {} }) {
    if (el.children.length !== 1) {
        throw new Error('elPositionFix: The element must have exactly one direct child.');
    }

    const child = el.children[0];
    const filler = document.createElement('div');
    filler.style.width = '100%';
    filler.style.height = window.getComputedStyle(child).height;

    const fix = (el) => {
        const rect = el.getBoundingClientRect();
        const computedStyle = window.getComputedStyle(el);
        const width = computedStyle.width;

        child.style.position = 'fixed';
        child.style.width = width;

        for (const key in style) {
            child.style[key] = style[key];
        }
    }

    fix(el);
    el.appendChild(filler);

    const resizeObserver = new ResizeObserver(() => {
        fix(el);
    });

    const childObserver = new ResizeObserver(() => {
        filler.style.height = window.getComputedStyle(child).height;
    });

    resizeObserver.observe(el);
    childObserver.observe(child);

    return {
        disconnect() {
            resizeObserver.disconnect();
            childObserver.disconnect();
        }
    };
}

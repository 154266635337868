import axios from 'axios';
import { reactive, computed } from 'vue';
import { useToast } from '@/plugins/toast';

export function useReaction(item_id, item_type, data) {
    let toast = null;

    try {
        toast = useToast();
    } catch (error) {
        console.warn(`useReaction should be called in setup for toast to work`);
    }

    const post = (type) => {
        axios.post('/api/reactions', {
            item_id,
            item_type,
            type
        }).then(() => {
            if (type == 2) {
                toast?.publish({
                    title: 'This content has been downvoted. It will now be shown to fewer users.',
                    color: 'success',
                })
            }
        });
    }

    const reaction = reactive({
        total_likes: data.total_likes,
        liked: computed(() => this.type === 1),
        disliked: computed(() => this.type === 2),
        type: data.user_reaction || 0,
        like() {
            if (this.liked) {
                this.total_likes = this.total_likes - 1;
                return this.neutralize();
            }

            this.total_likes = this.total_likes + 1;
            this.type = 1;
            post(1);
        },
        dislike() {
            if (this.disliked) {
                return  this.neutralize();
            }

            if (this.liked) {
                this.total_likes = this.total_likes - 1;
            }

            this.type = 2;
            post(2);
        },
        neutralize() {
            this.type = 0;
            post(0);
        },
    });

    reaction.liked = computed(() => reaction.type === 1);
    reaction.disliked = computed(() => reaction.type === 2);

    return reaction;
}

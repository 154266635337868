import { onMounted } from 'vue';
import { useRouter } from 'vue-router';

import { useStore } from '@/store';
import { useAjaxForm } from '@/utils/form';
import { useSettings } from '@/settings';
import { useToast } from '@/plugins/toast';

export const useLoginHandler = () => {
    const store = useStore();

    return {
        handle(response) {
            store.commit('user', response.data.data);
            localStorage.setItem('me', JSON.stringify(response.data.data));
        }
    }
}

export const useLogin = (data) => {
    const loginHandler = useLoginHandler();
    const router = useRouter();
    const form = useAjaxForm(data);
    form.retrievesCsrf();

    return {
        execute(redirect = null) {
            form.post('/auth/login', {
                onSuccess(response) {
                    loginHandler.handle(response);

                    if (redirect) {
                        router.push(redirect);
                    } else {
                        router.push({ name: 'feed' });
                    }
                }
            });
        },
        get processing() {
            return form.processing;
        },
        get form() {
            return form;
        }
    }
}

export const useRegister = (data) => {
    const toast = useToast();
    const loginHandler = useLoginHandler();
    const router = useRouter();
    const form = useAjaxForm(data);
    form.retrievesCsrf();
    form.onError(() => {
        if (form.errors.referral_code) {
            toast.error('Invalid referral code. To signup without a referral code, remove the referral code from the registration form and try again.');
        }
    });

    return {
        execute() {
            form.post('/auth/register', {
                onSuccess(response) {
                    loginHandler.handle(response);
                    router.push({ name: 'feed' });
                }
            });
        },
        get processing() {
            return form.processing;
        },
        get form() {
            return form;
        }
    }
}

export const useLogout = () => {
    const router = useRouter();
    const store = useStore();
    const form = useAjaxForm();
    form.retrievesCsrf();

    return {
        execute(replace = false) {
            form.post('/auth/logout', {
                onSuccess() {
                    store.commit('user', null);
                    localStorage.removeItem('me');
                    router.push({ name: 'login', replace });
                }
            });
        },
        get processing() {
            return form.processing;
        }
    }
}

export const useSetThemeColor = () => {
    const settings = useSettings();

    const setThemeColor = () => {
        const themeRef = document.querySelector('[data-theme-ref]');
        const metaThemeColor = document.querySelector('meta[name="theme-color"]');
        const backgroundColor = getComputedStyle(themeRef).backgroundColor;
        metaThemeColor.setAttribute('content', backgroundColor);
    }

    onMounted(() => {
        setThemeColor();

        settings.onUpdateDarkMode(() => {
            setThemeColor();
        });
    });
}


export const PostType = {
    POST: 1,
    QUESTION: 2,
};

export const PostView = {
    TRENDING: 1,
    LATEST: 2,
    SUBSCRIBED: 3,
}

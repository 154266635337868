<script setup>
import { SwitchRoot, SwitchThumb } from 'radix-vue';

defineEmits(['update:checked']);

defineProps({
    id: {
        required: false,
        type: String,
    },
    name: {
        required: false,
        type: String,
    },
    disabled: {
        required: false,
        type: Boolean,
    },
})

const switchState = defineModel();
</script>

<template>
  <div class="flex gap-2 items-start justify-between">
    <label
        class="leading-none pr-4 select-none block grow"
        :for="id"
    >
      <slot />
    </label>
    <SwitchRoot
        @update:checked="(e) => $emit('update:checked', e)"
        :id="id"
        :name="name"
        :disabled="disabled"
        v-model:checked="switchState"
        class="disabled:opacity-50 shrink-0 w-[42px] h-[25px] focus-within:outline focus-within:outline-black/30 flex bg-black/50 shadow-sm rounded-full relative data-[state=checked]:focus-within:outline-primary/30 data-[state=checked]:bg-primary/50 cursor-default"
    >
      <SwitchThumb
        class="block w-[21px] h-[21px] my-auto bg-white shadow-sm rounded-full transition-transform duration-100 translate-x-0.5 will-change-transform data-[state=checked]:bg-primary data-[state=checked]:translate-x-[19px]"
      />
    </SwitchRoot>
  </div>
</template>

<script setup>
import { ref } from 'vue';

import { useAjaxForm } from '@/utils/form';
import { useToast } from '@/plugins/toast';

import Alert from '@/components/Modals/Alert.vue';

const emit = defineEmits(['cancelled', 'success']);

const props = defineProps({
    member: {
        type: Object,
        required: true,
    },
});

const open = ref(true);

const form = useAjaxForm({
    role: props.member.role,
});

const toast = useToast();

const submit = (alert) => {
    alert.setProcessing(true);
    form.post(`/api/members/${props.member.id}/roles`, {
        onSuccess: (response) => {
            toast.success('Role assigned');
            emit('success', response);
        },
        onFinally: () => {
            alert.setProcessing(false);
        },
    });
}

const roles = [
    {
        id: 1,
        name: 'Administrator',
        description: 'Admins can edit or delete channel and assign moderator or member roles to other users.',
    },
    {
        id: 2,
        name: 'Moderator',
        description: 'Can ban users and delete posts.',
    },
    {
        id: 3,
        name: 'Member',
        description: 'Has no administrative role.',
    },
];
</script>

<template>
    <div>
        <Alert :disabled="form.isClean()" :open="open" @confirmed="submit" @cancelled="$emit('cancelled')" confirm-text="Assign Role" title="Assign Membership Role">
            <div>
                Select a role to assign to <span class="font-semibold">{{ member.user.name }}</span>.
            </div>

            <div class="my-4 flex flex-col gap-4">
                <div v-for="role in roles" :key="role.id" class="flex gap-2">
                    <input v-model="form.role" name="role" type="radio" :value="role.id" :id="`role.${role.id}`" />
                    <label class="block leading-none" :for="`role.${role.id}`">
                        <div class="font-semibold">
                            {{ role.name }}
                        </div>
                        <div class="text-sm opacity-80 leading-tight mt-0.5">
                            {{ role.description }}
                        </div>
                    </label>
                </div>
            </div>
        </Alert>
    </div>
</template>

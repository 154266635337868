<script setup>
import { computed, onBeforeMount, onMounted, ref } from 'vue';

import { useMyFeed } from '@/composables/feed';
import { useNotificationPermission } from '@/composables/permissions/notification';
import { usePwaInstall } from '@/plugins/pwa-install';

import Avatar from '@/components/Avatar.vue';
import Content from '@/views/_partials/Content.vue';
import EmptyList from '@/components/Renderer/EmptyList.vue';
import InfiniteScrollObserver from '@/components/Renderer/InfiniteScrollObserver.vue';
import Header from '@/views/_partials/header/Header.vue';
import HeaderTitle from '@/views/_partials/header/HeaderTitle.vue';
import ProfileDrawer from '@/components/ProfileDrawer.vue';
import PostListItem from '@/components/Post/PostListItem.vue';
import Search from '@/components/Search.vue';
import Skeleton from '@/components/Renderer/Skeleton.vue';

const showProfileDrawer = ref(false);
const feed = useMyFeed();

const pwa = usePwaInstall();

const isInstallAvailable = ref(pwa.isInstallAvailable);

const openPlayStore = () => {
    window.open('https://play.google.com/store/apps/details?id=ng.corperland.twa', '_blank');
};

onMounted(() => {
    setTimeout(() => {
        isInstallAvailable.value = pwa.isInstallAvailable;
    }, 1200);

    useNotificationPermission().promptOnce();
});
</script>

<template>
    <Content>
        <Header>
            <button class="flex" type="button" @click="showProfileDrawer = true" aria-label="show profile drawer">
                <Avatar class="md:hidden" :src="$store.state.user.profile.avatar?.url" :fallback-from="$store.state.user.name" />
                <ProfileDrawer :open="showProfileDrawer" @update:open="showProfileDrawer = false" />
            </button>
            <HeaderTitle>
                Feed
            </HeaderTitle>
            <div class="grow"></div>
            <template v-if="$store.state.deviceType === 'mobile' && pwa && isInstallAvailable && !pwa.isUnderStandaloneMode">
                <button @click="pwa.isAppleMobilePlatform ? pwa.showDialog() : openPlayStore()" class="block font-semibold bg-primary text-primary-foreground rounded-full px-2 py-1">
                    Open app
                </button>
            </template>
            <Search class="ml-auto" :placeholder="`Search for Posts on Corperland`" type="post" />
        </Header>

        <div class="py-4 md:px-4 bg-gray h-full">
            <div>
                <Skeleton :collection="feed.posts" />
                <EmptyList :collection="feed.posts" />
                <div class="flex flex-col gap-2" v-if="feed.posts.data">
                    <template v-for="post in feed.posts.data" :key="post.id">
                        <PostListItem as="div" v-if="!post.__client_meta?.injected" :post="post" />
                        <component v-else :is="post.component" v-bind="post.props" />
                    </template>
                </div>
                <InfiniteScrollObserver :collection="feed.posts" />
            </div>
        </div>
    </Content>
</template>

import { createRouter as createVueRouter, useRouter } from 'vue-router';

import routes from '@/routes/index';
import { useStore } from '@/store';

import { useNotificationPermission } from '@/composables/permissions/notification';

export function createRouter(history) {
    const router = createVueRouter({
        history,
        routes,
        scrollBehavior(to, from, savedPosition) {
            if (savedPosition) {
                return savedPosition;
            } else {
                return { top: 0 };
            }
        },
    });

    const callMiddlewareStack = async (context, middlewares, index = 0) => {
        const middleware = middlewares[index];

        const result = await middleware(context);

        if ((result === true || typeof result === 'undefined') && middlewares[index + 1]) {
            return callMiddlewareStack(context, middlewares, index + 1);
        }

        return result;
    }

    router.beforeEach(async (to, from) => {
        const context = {
            to,
            from,
            router: useRouter(),
            store: useStore(),
            notification: useNotificationPermission(),
        };

        if (to.meta.middlewares && to.meta.middlewares.length > 0) {
            const result = await callMiddlewareStack(context, to.meta.middlewares);

            return result;
        }
    });

    return router;
}

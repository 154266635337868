<script setup>
import { computed, onMounted, onUnmounted } from 'vue';

import axios from '@/axios';
import echo from '@/plugins/echo';
import { useStore } from '@/store';
import { getFriendlyTime, getEventMessageContent } from '@/utils';

import Avatar from '@/components/Avatar.vue';
import IconCheckAll from '@/components/Icons/CheckAll.vue';

const props = defineProps({
    conversation: {
        type: Object,
        required: true,
    },
});

const store = useStore();

let channel = null;
let conversationChannel = null;

const conversationLastMessageTime = computed(() => {
    return getFriendlyTime(props.conversation.last_message.created_at, {
        withTime: false,
    });
});

const messageCreated = (event) => {
    axios.get(`/api/conversations/${props.conversation.id}`).then((response) => {
        props.conversation.last_message = response.data.data.last_message;
    });
};

const messageViewed = (event) => {
    props.conversation.last_message.read_at = event.viewed_at;
}

const messagesDeleted = (event) => {
    if (event.member_message_ids.includes(props.conversation.last_message.id)) {
        axios.get(`/api/conversations/${props.conversation.id}`).then((response) => {
            props.conversation.last_message = response.data.data.last_message;
        });
    }
}

const conversationAccepted = (event) => {
    props.conversation.conversation.accepted = event.accepted;
}

onMounted(() => {
    channel = echo.private(`conversation-members.${props.conversation.id}`);

    channel.listen('.message:created', messageCreated)
        .listen('.messages:viewed', messageViewed)
        .listen('.messages:deleted', messagesDeleted)
        .listen('.conversation:accepted', conversationAccepted);

    conversationChannel = echo.private(`conversations.${props.conversation.conversation.id}`)
        .listen('.message:created', messageCreated);
});

onUnmounted(() => {
    if (channel) {
        channel.stopListening('.message:created', messageCreated)
            .stopListening('.messages:deleted', messagesDeleted)
            .stopListening('.messages:viewed', messageViewed)
            .stopListening('.conversation:accepted', conversationAccepted);
    }

    if (conversationChannel) {
        conversationChannel.stopListening('.message:created', messageCreated);
    }
});
</script>

<template>
    <div class="flex gap-2 p-4">
        <Avatar :src="conversation.conversation.with_me ? $store.state.user.profile.avatar?.url : conversation.other_members[0].user.profile.avatar?.url" size="lg" :fallback-from="conversation.conversation.with_me ? $store.state.user.name : conversation.other_members[0].user.name" />
        <div class="grow min-w-0">
            <div class="flex">
                <div class="truncate">
                    <span :class="[
                        conversation.last_message && !conversation.last_message.read_at ? 'font-semibold' : 'font-medium'
                    ]">{{ conversation.conversation.with_me ? $store.state.user.name + ' (You)' : conversation.other_members[0].user.name }}</span> <span class="opacity-70">@{{ conversation.conversation.with_me ? $store.state.user.profile.username : conversation.other_members[0].user.profile.username }}</span>
                </div>
                <div class="shrink-0 pl-4 ml-auto self-center" v-if="conversation.last_message">
                    <div class="text-sm capitalize" :class="[
                        conversation.last_message.read_at ? 'text-white-foreground/70' : 'text-primary font-medium'
                    ]">
                        {{ conversationLastMessageTime }}
                    </div>
                </div>
            </div>
            <div class="flex">
                <div
                    v-if="conversation.last_message"
                    class="truncate mr-4"
                    :class="{
                        'font-semibold text-white-foreground': !conversation.last_message.read_at,
                        'text-white-foreground/70': conversation.last_message.read_at
                    }"
                >
                    <div class="truncate text-sm" v-if="conversation.last_message.message?.type == 2">
                        {{ getEventMessageContent(conversation.last_message.message, conversation) }}
                    </div>
                    <template v-else>
                        <div class="italic truncate" v-if="conversation.last_message.deleted">
                            You deleted this message
                        </div>
                        <div class="italic truncate" v-else-if="!conversation.last_message.message">
                            This message was deleted
                        </div>
                        <div class="flex items-center gap-1" v-else>
                            <IconCheckAll v-if="conversation.last_message?.from_me" class="size-5 shrink-0" />
                            <div class="truncate">{{ conversation.last_message.message.content ? conversation.last_message.message.content : (conversation.last_message.message.files ? 'New file' : '') }}</div>
                        </div>
                    </template>
                </div>
                <div v-if="conversation.last_message && !conversation.last_message.read_at" class="self-center shrink-0 text-sm text-primary-foreground bg-primary size-3 rounded-full mt-0.5"></div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useSetThemeColor } from '@/composables/actions';

useSetThemeColor();
</script>

<template>
    <main data-theme-ref class="flex flex-row relative w-full min-h-screen mx-auto xl:max-w-[1280px] bg-white text-white-foreground">
        <slot />
    </main>
</template>

<script setup>
import { computed } from 'vue';

import InfiniteScrollObserver from '@/components/Renderer/InfiniteScrollObserver.vue';
import ListCard from '@/components/Conversation/ListCard.vue';
import Skeleton from '@/components/Renderer/Skeleton.vue';

const props = defineProps({
    conversations: {
        type: Object,
        required: true,
    },
    routeQuery: {
        type: Object,
        required: false,
    },
    routeReplace: {
        type: Boolean,
        default: false,
    },
});

const sortConversations = computed(() => {
    if (!props.conversations.data) {
        return props.conversations.data;
    }

    return props.conversations.data.sort((a, b) => {
        if (!a.last_message && !b.last_message) {
            return new Date(b.created_at) - new Date(a.created_at);
        }

        const dateA = new Date(a.last_message?.created_at || a.created_at);
        const dateB = new Date(b.last_message?.created_at || b.created_at);

        return dateB - dateA;
    });
});
</script>

<template>
    <div>
        <Skeleton :collection="conversations" />
        <ul v-if="conversations.fetched && conversations.data.length > 0">
            <li v-for="conversation in sortConversations" :key="conversation.id" class="border-b border-white-foreground/10" :class="{
                'bg-white-foreground/5': $route.name === 'conversations.show' && $route.params.id === conversation.id
            }">
                <RouterLink class="block" :to="{
                    name: 'conversations.show',
                    params: { id: conversation.id },
                    query: routeQuery,
                }" :replace="routeReplace">
                    <ListCard :conversation="conversation" />
                </RouterLink>
            </li>
        </ul>
        <InfiniteScrollObserver :collection="conversations" />
    </div>
</template>


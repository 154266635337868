<script setup>
const model = defineModel();

defineProps({
    name: {
        type: String,
        required: false,
    },
    id: {
        type: String,
        required: false,
    },
    value: {
        required: true,
    }
})
</script>

<template>
    <div class="flex gap-2">
        <input v-model="model" :name="name" type="radio" :id="id" :value="value" />
        <label class="block leading-none" :for="id">
            <slot></slot>
        </label>
    </div>
</template>

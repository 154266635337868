<script setup>
import { computed, nextTick, onMounted, onUnmounted, ref } from 'vue';
import { RouterLink } from 'vue-router';

import { useStore } from '@/store';
import { useToast } from '@/plugins/toast';

import Avatar from '@/components/Avatar.vue';
import AppButton from '@/components/Button/Button.vue';
import ChannelLink from '@/components/Link/ChannelLink.vue';
import Content from '@/views/_partials/Content.vue';
import CreatePostModal from '@/components/Post/CreatePostModal.vue';
import IconAccountLock from '@/components/Icons/AccountLock.vue';
import IconArrowUpThick from '@/components/Icons/ArrowUpThick.vue';
import IconBan from '@/components/Icons/Ban.vue';
import IconPencilOff from '@/components/Icons/PencilOff.vue';
import IconPencilPlus from '@/components/Icons/PencilPlus.vue';
import Header from '@/views/_partials/header/Header.vue';
import PostList from '@/components/Post/PostList.vue';

const props = defineProps({
    channel: Object,
    posts: Object,
    feed: Object,
});

const store = useStore();
const toast = useToast();

const showPostModal = ref(false);

const windowScrollY = ref(0);

const showNewPostsButton = computed(() => {
    if (windowScrollY.value < 10) {
        return false;
    }

    return props.feed.new_posts;
});

const postingDisabled = computed(() => {
    if (!props.channel.filled()) {
        return false;
    }

    return props.channel.data.posting_disabled && props.channel.data.user_membership?.role_abilities['posts:bypass-restriction'] == false;
});

const handleOpenPostModal = () => {
    if (postingDisabled.value) {
        toast.info('Only admins can post to this channel');
        return;
    }

    showPostModal.value = true;
}

const resetNewPosts = () => {
    windowScrollY.value = window.scrollY;

    if (windowScrollY.value < 10) {
        props.feed.new_posts = false;
    }
};

const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
};

onMounted(() => {
    windowScrollY.value = window.scrollY;
    window.addEventListener('scroll', resetNewPosts);
});

onUnmounted(() => {
    window.removeEventListener('scroll', resetNewPosts);
});
</script>

<template>
    <Content>
        <Header class="relative" with-back-button>
            <div v-if="channel.fetching()" class="w-full md:w-1/2 animate-pulse">
                <div class="w-full h-4 rounded-full bg-white-foreground/20"></div>
                <div class="w-full h-2 rounded-full bg-white-foreground/20 mt-1"></div>
            </div>
            <template v-if="channel.filled()">
                <Avatar :rounded-full="false" :src="channel.data.image?.url || '/channel-placeholder.jpg'" />
                <ChannelLink class="leading-none truncate block grow" name="channels.info" :channel="channel.data">
                    <div v-if="channel.data">
                        <h1 class="font-medium truncate text-xl leading-none">{{ channel.data.name }}</h1>
                        <div class="text-xs text-white-foreground/60">
                            Tap here for channel info
                        </div>
                    </div>
                </ChannelLink>
            </template>
            <div v-if="showNewPostsButton" class="absolute -bottom-12 w-full flex justify-center">
                <button type="button" @click="scrollToTop" class="bg-primary text-primary-foreground rounded-full py-2 px-4 flex items-center gap-1">
                    <IconArrowUpThick class="size-5" />
                    <span>new posts</span>
                </button>
            </div>
        </Header>

        <template v-if="channel.filled()">
            <template v-if="channel.data.user_membership?.status === store.state.app.enums.member_status.APPROVED">
                <div class="md:px-4 py-4 h-full bg-gray">
                    <PostList :collection="posts" />
                </div>

                <div class="fixed bottom-4 right-6">
                    <button
                        @click.prevent="handleOpenPostModal"
                        type="button"
                        aria-label="Create Post"
                        class="bg-primary text-primary-foreground rounded-full p-2"
                        :class="{
                            'opacity-80': postingDisabled,
                        }"
                    >
                        <component :is="postingDisabled ? IconPencilOff : IconPencilPlus" class="size-7" />
                    </button>
                    <CreatePostModal
                        v-if="showPostModal"
                        :open="showPostModal"
                        @update:open="(value) => showPostModal = value"
                        :defaultChannel="channel.data" />
                </div>
            </template>
            <template v-else>
                <div class="flex flex-col justify-center items-center h-[700px]">
                    <template v-if="channel.data.user_membership?.status === store.state.app.enums.member_status.BANNED">
                        <IconBan class="size-28" />
                        <div class="text-center mt-6 w-[80%]">
                            <h2 class="text-3xl font-semibold">
                                Banned
                            </h2>
                            <div class="mt-1">
                                You have been banned from <span class="font-semibold">{{ channel.data.name }}</span>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <IconAccountLock class="size-28" />
                        <div class="text-center mt-6 w-[80%]">
                            <h2 class="text-3xl font-semibold">
                                Join Channel
                            </h2>
                            <div class="mt-1">
                                You are not yet a member of <span class="font-semibold">{{ channel.data.name }}</span>. Join now to connect and stay updated with the latest activities in this channel.
                            </div>
                        </div>

                        <div class="mt-6">
                            <AppButton color="primary-border" capitalize :as="RouterLink" :to="{ name: 'channels.info', params: { handle: channel.data.handle } }">
                                Join Channel
                            </AppButton>
                        </div>
                    </template>
                </div>
            </template>
        </template>
    </Content>
</template>

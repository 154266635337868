import { inject, reactive } from "vue";

import axios from '@/axios';

const key = Symbol('notifications');

const createPlugin = () => {
    return {
        clearUnreadNotificationsCount() {
            return axios.delete('/api/me/unread-notifications-count');
        },
        markAsRead(notificationId) {
            return axios.post(`/api/notifications/${notificationId}/read`);
        },
        markAllAsRead() {
            return axios.post('/api/notifications/mark-all-as-read');
        },
    };
}

export function useNotifications() {
    const app = inject(key);

    if (!app) {
        throw new Error('useNotifications() is called without provider.');
    }

    return app;
}

export default {
    install(app) {
        const plugin = createPlugin();
        app.provide(key, plugin);
    }
}

import '@khmyznikov/pwa-install';
import { inject } from "vue";

const pwaInstallKey = Symbol('pwaInstall');

const createPwaInstallPlugin = () => {
    let el = {};

    if (typeof window !== 'undefined') {
        el = document.createElement('pwa-install');
        el.setAttribute('manifest-url', '/app.webmanifest');
        el.setAttribute('manual-apple', 'true');
        el.setAttribute('manual-chrome', 'true');
        document.body.appendChild(el);
    }

    return el;
}

export function usePwaInstall() {
    if (typeof window === 'undefined') {
        return {};
    }

    const pwaInstall = inject(pwaInstallKey);

    if (!pwaInstall) {
        throw new Error('usePwaInstall() is called without provider.');
    }

    return pwaInstall;
}

export default {
    install(app) {
        const pwaInstall = createPwaInstallPlugin();
        app.config.globalProperties.$pwaInstall = pwaInstall;
        app.provide(pwaInstallKey, pwaInstall);
    }
}

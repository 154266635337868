<script setup>
import LoadingScreen from '@/components/LoadingScreen.vue';
import { useLogout } from '@/composables/actions';
import { onMounted } from 'vue';

const logout = useLogout();

onMounted(() => {
    logout.execute(true);
});
</script>

<template>
    <LoadingScreen :open="true" />
</template>

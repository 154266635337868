<script setup>
import { computed, onMounted, ref } from "vue";

import IconCancelBold from '@/components/Icons/CancelBold.vue';
import IconError from '@/components/Icons/Error.vue';
import IconLoader from '@/components/Icons/Loader.vue';

const props = defineProps({
    picker: {
        required: false,
        type: Object,
    }
});

const showImage = ref(false);

const files = computed(() => {
    if (!props.picker) {
        return [];
    }

    const modelFiles = Array.isArray(props.picker.model) ? props.picker.model : [props.picker.model];

    const pendingOrFailedFiles = props.picker.newFiles.filter(f => f.status != 2);

    return [...modelFiles, ...pendingOrFailedFiles].sort((a, b) => a.order - b.order);
});

onMounted(() => {
    setTimeout(() => {
        showImage.value = true;
    }, 100);
});
</script>

<template>
    <ul v-if="files.length > 0" class="flex flex-row gap-4 w-full overflow-auto">
        <li v-for="file, i in files" :key="i">
            <div class="relative h-24 borde flex items-end">
                <div class="relative h-20 w-20 rounded-md">
                    <div class="absolute w-full h-full bg-black/10 left-0 top-0 rounded-[inherit]">
                    </div>
                    <div v-if="file.status == 1 || file.status == 3" class="w-full h-full absolute top-0 left-0 flex items-center justify-center">
                        <IconError v-if="file.status == 3" class="size-6 text-[#ffffff]" />
                        <IconLoader v-else-if="file.status == 1" class="size-6 text-[#ffffff]" />
                    </div>
                    <img
                        v-if="showImage"
                        class="h-full w-full object-cover rounded-[inherit]"
                        :src="(file.thumbnails && file.thumbnails.find((t) => t.height == 150)) ? file.thumbnails.find(t=>t.height == 150).url : file.url"
                        loading="lazy"
                    />
                </div>
                <button
                    v-if="file.status != 1"
                    @click="picker.removeFile(file)"
                    aria-label="remove file"
                    type="button"
                    class="flex items-center justify-center bg-[#D62828] text-[#f5f5f5] size-4 rounded-full absolute top-2 -right-2"
                >
                    <IconCancelBold class="size-3" />
                </button>
            </div>
        </li>
    </ul>
</template>

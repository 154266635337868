<script setup>
import { useRouter } from 'vue-router';

import { useAjaxForm } from '@/utils/form';
import { useToast } from '@/plugins/toast';

import AppButton from '@/components/Button/Button.vue';
import Modal from '@/components/Modals/Modal.vue';
import ModalTitle from '@/components/Modals/ModalTitle.vue';

const toast = useToast();
const form = useAjaxForm();
const router = useRouter();

form.onSuccess(() => {
    router.back();
    toast.success('We will let you know when we finish the process.');
});
</script>

<template>
    <Modal :open="true" @update:open="router.back()">
        <ModalTitle :show="true">
            <h1 class="text-2xl font-semibold">Deactivate your account</h1>
        </ModalTitle>

        <div class="mt-4">
            <p class="mb-4">Are you sure you want to deactivate your account?</p>
            <p class="mb-4">This process will take 24 hours. After deactivation, you will lose access to your account and your profile will be permanently deleted.</p>
        </div>
        <div class="flex gap-2 mt-4">
            <div class="w-1/2">
                <AppButton color="default" @button:click="$router.back()" full>
                    Cancel
                </AppButton>
            </div>
            <div class="w-1/2">
                <AppButton color="danger" full :loading="form.processing" @button:click="form.post('/api/me/delete-account')">
                    Deactivate
                </AppButton>
            </div>
        </div>
    </Modal>
</template>

<script setup>
import { getCurrentInstance } from 'vue';
import { useRouter } from 'vue-router';

import { useAjaxForm } from '@/utils/form';
import { useStore } from '@/store';
import { useNotifications } from '@/composables/notifications';

import Avatar from '@/components/Avatar.vue';
import LineClamp from '@/components/LineClamp.vue';

const instance = getCurrentInstance();
const props = defineProps({
    notification: {
        required: true,
        type: Object,
    },
    as: {
        default: 'li',
    }
});

const markAsRead = useAjaxForm();
const store = useStore();
const router = useRouter();
const notifications = useNotifications();

const goToComment = () => {
    if (!props.notification.read) {
        notifications.markAsRead(props.notification.id);
    }

    router.push({
        name: 'profiles.show',
        params: {
            username: props.notification.data.sender_username,
        }
    });
}
</script>

<template>
    <component :is="as">
        <button
            :disabled="markAsRead.processing"
            @click="goToComment"
            :class="{
                'bg-primary-light': !notification.read,
                'bg-white': notification.read,
                'opacity-60': markAsRead.processing
            }"
            class="relative flex gap-2 py-4 px-4 text-white-foreground text-left w-full" type="button">
            <Avatar size="md" class="shrink-0" :src="notification.data.sender_avatar" :fallback-from="notification.data.sender_name" />
            <div class="flex items-start gap-2 leading-tight w-[calc(100%-44px)]">
                <div class="grow w-[inherit]">
                    <div class="text-sm">
                        <span class="font-semibold">{{ notification.data.sender_name }}</span> @{{notification.data.sender_username}} wants to connect with you.
                    </div>
                </div>
                <span class="block shrink-0 text-sm text-white-foreground/60">{{ notification.created_at_diff }}</span>
            </div>
            <div v-if="!notification.read" class="bg-primary rounded-full w-2 h-2 absolute left-1 top-1/2 -translate-y-1/2"></div>
        </button>
    </component>
</template>

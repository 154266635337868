<script setup>
import { ref } from 'vue';

import { useAjaxForm } from '@/utils/form';
import { useToast } from '@/plugins/toast';

import Alert from '@/components/Modals/Alert.vue';

const emit = defineEmits(['cancelled', 'success']);

const props = defineProps({
    member: {
        type: Object,
        required: true,
    },
});

const open = ref(true);
const form = useAjaxForm();
const toast = useToast();

const ban = (alert) => {
    const url = props.member.status == 3 ? `/api/members/${props.member.id}/restore` : `/api/members/${props.member.id}/ban`;
    alert.setProcessing(true);
    form.post(url, {
        onSuccess: (response) => {
            toast.success(props.member.status == 3 ? 'Ban lifted' : 'Banned');
            emit('success', response);
        },
        onFinally: () => {
            alert.setProcessing(false);
        },
    });
}
</script>

<template>
    <div>
        <Alert :open="open" @confirmed="ban" @cancelled="$emit('cancelled')" danger :confirm-text="member.status == 3 ? 'Lift Ban' : 'Ban'" :title="member.status == 3 ? 'Lift Ban' : 'Ban'">
            <div>
                Are you sure you want to {{ member.status == 3 ? 'lift the ban on' : 'ban' }} <span class="font-semibold">{{ member.user.name }}</span>?
            </div>
        </Alert>
    </div>
</template>

<script setup>
import anon from '@images/anonymous-user.jpg';

import { computed, getCurrentInstance, nextTick, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

import { PostType } from '@/enums';
import { useAjaxForm } from '@/utils/form';
import { elPositionFix } from '@/utils/fixed';

import AppButton from '@/components/Button/Button.vue';
import Avatar from '@/components/Avatar.vue';
import ChannelIcon from '@/components/Channel/ChannelIcon.vue';
import IconCancel from '@/components/Icons/CancelBold.vue';
import IconImages from '@/components/Icons/Images.vue';
import FilePicker from '@/components/Form/FilePicker.vue';
import FilePickerDisplay from '@/components/Form/FilePickerDisplay.vue';
import FormButton from '@/components/Form/FormButton.vue';
import IconCaretRightFill from '@/components/Icons/CaretRightFill.vue';
import PostTextFields from '@/components/Post/PostTextFields.vue';
import UserAvatar from '@/components/User/UserAvatar.vue';

const emit = defineEmits(['back', 'postPublished']);

const props = defineProps({
    question: {
        default: false,
        type: Boolean,
    },
    channel: {
        required: true,
    }
});

const type = props.question ? 'question' : 'post';
const router = useRouter();
const bottombar = ref();
const picker = ref();
let bottombarFix = null;

const form = useAjaxForm({
    title: null,
    content: null,
    channel_id: props.channel.id,
    location_id: null,
    type: props.question ? PostType.QUESTION : PostType.POST,
    files: [],
    anonymously: false,
});

const titleIsValid = () => {
    if (!props.question) {
        return true;
    }

    return form.title
        && form.title.length >= 10
        && form.title.length <= 200;
}

const contentIsValid = () => {
    if (props.question) {
        return !form.content || form.content.length <= 4000;
    }

    return form.content
        && form.content.length > 0
        && form.content.length <= 4000;
}

const formIsValid = () => {
    return titleIsValid() && contentIsValid();;
}

const canSubmitForm = computed(() => {
    return formIsValid() && form.channel_id;
});

const postPublished = (post) => {
    router.push({
        name: 'posts.show',
        params: {
            id: post.id,
        }
    });

    emit('postPublished', post);
}

onMounted(() => {
    nextTick(() => {
        bottombarFix = elPositionFix({
            el: bottombar.value,
            style: {
                position: 'absolute',
                zIndex: 50,
                bottom: 0,
            }
        });
    });
});
</script>

<template>
    <form class="w-full"
        @submit.prevent="form.post('/api/posts', { onSuccess: (response) => postPublished(response.data.data) })">

        <div class="flex items-center w-full">
            <Avatar class="shrink-0" v-if="form.anonymously" :src="anon" />
            <UserAvatar v-else class="shrink-0" :user="$store.state.user" />

            <IconCaretRightFill class="size-5 opacity-70 ml-1 shrink-0" />

            <button
                type="button"
                aria-label="change channel"
                @click="emit('back')"
                class="min-w-0 flex items-center gap-0.5 text-white-foreground/80 ml-1 rounded-full border border-white-foreground/20 py-1 px-2">
                <IconCancel class="size-4" />
                <ChannelIcon :channel="channel" class="size-4" />
                <div class="text-sm font-semibold leading-none flex items-center mt-[1px] min-w-0 truncate">{{ channel.name }}</div>
            </button>
        </div>

        <div class="mt-4">
            <PostTextFields :draft-key="`draft_${props.channel.id}_${type}`" :type="question ? PostType.QUESTION : PostType.POST" :form="form" />
        </div>

        <div v-if="question" class="mt-4 flex items-center gap-2">
            <input v-model="form.anonymously" name="anonymously" type="checkbox" :value="false"
                id="anonymously" />
            <label class="text-sm" for="anonymously">Ask Anonymously</label>
        </div>

        <FilePickerDisplay class="pb-4" :picker="picker" />

        <div ref="bottombar">
            <div class="w-full mt-4 flex items-center gap-2 py-3 border-t border-white-foreground/20 px-4 md:px-6">
                <FilePicker
                    ref="picker"
                    v-model="form.files"
                    aria-label="add images or videos"
                    type="button"
                    multiple
                    class="text-white-foreground/90"
                >
                    <IconImages class="size-6" />
                </FilePicker>
                <div class="grow"></div>
                <FormButton :disabled="!canSubmitForm" :form="form" capitalize compact color="primary">{{
                    question ? 'Ask' : 'Post'}}</FormButton>
            </div>
        </div>
    </form>
</template>

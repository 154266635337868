<script setup>
import { RouterLink, useRouter } from 'vue-router';

import { useAjaxForm } from '@/utils/form';
import { useStore } from '@/store';
import { useToast } from '@/plugins/toast';

import Content from '@/views/_partials/Content.vue';
import FormInput from '@/components/Form/FormInput.vue';
import FormButton from '@/components/Form/FormButton.vue';
import Header from '@/views/_partials/header/Header.vue';
import HeaderTitle from '@/views/_partials/header/HeaderTitle.vue';
import Main from '@/views/_partials/Main.vue';

const store = useStore();
const toast = useToast();
const router = useRouter();
const form = useAjaxForm({
    name: store.state.user.name,
    username: '',
    gender: '',
    type: '',
    type_details: {
        batch: '',
        batch_stream: '',
        batch_year: '',
        batch_state: '',
    },
    avatar: '',
    bio: '',
    school: '',
});

form.onSuccess((response) => {
    store.commit('user', response.data.data);
    toast.publish({
        title: 'Profile created successfully, cheers!!!',
        color: 'success',
    });
    router.replace({ name: 'feed' });
});
</script>

<template>
<div class="lg:w-[700px] mx-auto">
    <Main>
        <Content class="bg-white text-white-foreground">
            <Header>
                <div v-if="$store.state.deviceType !== 'mobile'" class="w-[40px] h-[40px]">
                    <img src="/icon-512.png" />
                </div>
                <HeaderTitle>
                    Create your profile
                </HeaderTitle>

                <RouterLink class="block ml-auto font-semibold text-primary border-primary border rounded-full px-4 py-1" :to="{ name: 'logout' }">
                    Logout
                </RouterLink>
            </Header>
            <div class="p-4">
                <p>Welcome to Corperland <span class="font-semibold">{{ $store.state.user.name }}</span>! Let's help you create your profile to get started.</p>

                <form class="mt-8" @submit.prevent="form.post('/api/profiles')">
                    <FormInput :form="form" as="file" name="avatar" :input-bind="{
                        accept: 'image/png,image/jpeg,image/jpg,image/webp,image/heic',
                        roundedFull: true,
                        ariaLabel: 'Avatar',
                        size: '3xl',
                    }"/>
                    <FormInput label="Name" name="name" :form="form" />
                    <FormInput lowercase label="Username" name="username" :form="form" help="You can change this only after 30days" />
                    <FormInput
                        :form="form"
                        as="select"
                        name="gender"
                        label="Gender"
                        placeholder="Select your gender"
                        required
                        :input-bind="{
                            options: store.state.app.gender_options
                        }"
                    />
                    <FormInput :form="form" as="select" name="school" label="School" placeholder="Select your school"
                        required :input-bind="{
                        options: store.state.app.school_options
                    }" />
                    <FormInput :form="form" as="textarea" name="bio" label="Bio" placeholder="Tell us about yourself" />
                    <FormInput
                        :form="form"
                        as="select"
                        name="type"
                        label="Which profile type best suits you?"
                        placeholder="Select One"
                        required
                        :input-bind="{
                            options: store.state.app.profile_type_options
                        }"
                    />
                    <FormInput
                        v-if="form.type == 1 || form.type == 3"
                        :form="form"
                        as="input"
                        name="type_details.batch_year"
                        label="Year of Deployment"
                        placeholder="2xxx"
                        v-model="form.type_details.batch_year"
                        :input-bind="{
                            min: 1960,
                            max: 2024,
                            type: 'number'
                        }"
                    />
                    <FormInput
                        v-if="form.type == 1 || form.type == 3"
                        :form="form"
                        v-model="form.type_details.batch"
                        as="select"
                        name="type_details.batch"
                        label="Batch"
                        placeholder="Select Batch"
                        required
                        :input-bind="{
                            options: {
                                'A': 'A',
                                'B': 'B',
                                'C': 'C',
                            }
                        }"
                    />
                    <FormInput
                        v-if="form.type == 1 || form.type == 3"
                        :form="form"
                        v-model="form.type_details.batch_stream"
                        as="select"
                        name="type_details.batch_stream"
                        label="Stream"
                        placeholder= "Select Stream"
                        required
                        :input-bind="{
                            options: {
                                'I': 'I',
                                'II': 'II',
                                'III': 'III',
                            }
                        }"
                    />
                    <FormInput
                        v-if="form.type == 1 || form.type == 3"
                        :form="form"
                        v-model="form.type_details.batch_state"
                        as="select"
                        name="type_details.batch_state"
                        label="State of Deployment"
                        placeholder="Select State Of Deployment"
                        required
                        :input-bind="{
                            options: store.state.app.location_options
                        }"
                    />

                    <div class="mt-10">
                        <FormButton :form="form" full color="primary">
                        Create Profile
                        </FormButton>
                    </div>
                </form>
            </div>
        </Content>
    </Main>
</div>
</template>


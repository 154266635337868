<script setup>
import TextBox from '@/components/TextBox.vue';

const model = defineModel();

const props = defineProps({
    borderless: {
        default: false,
        type: Boolean,
    },
    rows: {
        default: 4,
        type: Number,
    },
    maxRows: {
        default: 10,
        type: Number,
    },
    placeholder: {
        type: String,
        required: false,
    },
    name: {
        type: String,
        required: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    required: {
        type: Boolean,
        default: false,
    },
    id: {
        type: String,
        required: false,
    },
});
</script>

<template>
    <div
        class="
        block w-full focus:outline-none
        border-0 ring-inset ring-black/20
        focus-within:ring-black/30
        invalid:focus-within:ring-rose-200 invalid:focus-within:border-rose-600
        group-invalid:focus-within:ring-rose-200 group-invalid:focus-within:border-rose-600
        bg-white text-white-foreground px-4 py-2"
        :class="[
            borderless ? 'ring-0 focus:ring-0 rounded-[inherit]' : 'ring-1 focus:ring-1 rounded-lg'
        ]"
        >
        <TextBox :disable-username-suggestion="true" v-model="model" :max-rows="maxRows" :rows="rows" :placeholder="placeholder" :name="name" :disabled="disabled" :required="required" :id="id" />
        </div>
</template>

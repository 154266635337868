<script setup>
import 'photoswipe/style.css';

import { computed, onMounted, onUnmounted, ref } from "vue";
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import Photoswipe from 'photoswipe';

const props = defineProps({
    images: {
        required: true,
        type: Array,
    },
});

const gallery = ref(null);
let lightbox = null;

const galleryWidth = ref(null);

let galleryObserver = null;

onMounted(() => {
    galleryObserver = new ResizeObserver(() => {
        galleryWidth.value = gallery.value.getBoundingClientRect()['width'];
    });

    if (!lightbox && props.images.length > 0) {
        lightbox = new PhotoSwipeLightbox({
            gallery: gallery.value,
            children: 'a',
            pswpModule: Photoswipe,
        });

        lightbox.init();
    }
    galleryWidth.value = gallery.value.getBoundingClientRect()['width'];
    galleryObserver.observe(gallery.value);
});

const thumbnailHeight = computed(() => {
    return props.images.length > 2 ? 150 : 300;
});

const getMinHeight = computed(() => {
    if (props.images.length == 0) {
        return 0;
    }

    if (props.images.length >= 2) {
        return 300;
    }

    if (!galleryWidth.value) {
        return 0;
    }

    const thumbnail = props.images[0].thumbnails ? props.images[0].thumbnails.find((t) => t.height == 900) : null;

    const image =  thumbnail ? thumbnail : props.images[0];

    if (!image.height || !image.width) {
        return 150;
    }

    return Math.min((galleryWidth.value / image.width) * image.height, galleryWidth.value);
});

onUnmounted(() => {
    if (lightbox) {
        lightbox.destroy();
        lightbox = null;
    }
    galleryObserver?.disconnect();
});
</script>

<template>
    <div ref="gallery">
        <div v-if="images.length > 0" class="flex flex-wrap gap-1 border border-white-foreground border-white-foreground/10 rounded-3xl"
            :style="{
                minHeight: getMinHeight ? getMinHeight + 'px' : undefined,
            }">
        <template v-if="images.length === 1">
            <a v-for="image, i in images" :key="i" class="block w-full" :href="image.url" :data-pswp-width="image.width" :data-pswp-height="image.height">
                <img v-if="image.type == 1"
                    :style="{
                        maxHeight: galleryWidth ? galleryWidth + 'px' : undefined,
                    }"
                    class="w-full object-cover rounded-3xl"
                    draggable="true"
                    loading="lazy"
                    :src="(image.thumbnails && image.thumbnails.find((t) => t.height == 900)) ? image.thumbnails.find(t=>t.height == 900).url : image.url" />
            </a>
        </template>
        <template v-else-if="images.length === 2">
            <a v-for="image, i in images" :key="i" :style="{
                width: 'calc(50% - 2px)'
            }" class="block" :href="image.url" :data-pswp-width="image.width" :data-pswp-height="image.height">
                <img v-if="image.type == 1"
                    :style="{
                        height: '300px',
                    }"
                    class="w-full object-cover"
                    :class="{
                        'rounded-l-3xl': i === 0,
                        'rounded-r-3xl': i === 1
                    }"
                    draggable="true"
                    loading="lazy"
                    :src="(image.thumbnails && image.thumbnails.find((t) => t.height == 900)) ? image.thumbnails.find(t=>t.height == 900).url : image.url" />
            </a>
        </template>
        <template v-else-if="images.length === 3">
            <a class="block" style="width: calc(50% - 2px)" :href="images[0].url" :data-pswp-width="images[0].width" :data-pswp-height="images[0].height">
                <img v-if="images[0].type == 1"
                    :style="{
                        height: '300px',
                    }"
                    class="w-full object-cover rounded-l-3xl"
                    draggable="true"
                    loading="lazy"
                    :src="(images[0].thumbnails && images[0].thumbnails.find((t) => t.height == 900)) ? images[0].thumbnails.find(t=>t.height == 900).url : images[0].url" />
            </a>
            <div class="flex flex-col gap-1 border-r-3xl" style="width: calc(50% - 2px); height: 300px;">
                <a v-for="image, i in images.slice(1)" :key="i" :style="{
                    height: 'calc(50% - 2px)'
                }" class="block" :href="image.url" :data-pswp-width="image.width" :data-pswp-height="image.height">
                    <img v-if="image.type == 1"
                        class="w-full h-full object-cover"
                        :class="{
                            'rounded-tr-3xl': i === 0,
                            'rounded-br-3xl': i === 1,
                        }"
                        draggable="true"
                        loading="lazy"
                        :src="(image.thumbnails && image.thumbnails.find((t) => t.height == 900)) ? image.thumbnails.find(t=>t.height == 900).url : image.url" />
                </a>
            </div>
        </template>
        <template v-else-if="images.length >= 4">
            <a v-for="image, i in images" :key="i" :style="{
                width: 'calc(50% - 2px)'
            }" class="block relative" :class="{
                'hidden': i > 3
            }" :href="image.url" :data-pswp-width="image.width" :data-pswp-height="image.height">
                <img v-if="image.type == 1"
                    :style="{
                        height: '150px',
                    }"
                    class="w-full object-cover"
                    :class="{
                        'rounded-tl-3xl': i === 0,
                        'rounded-tr-3xl': i === 1,
                        'rounded-bl-3xl': i === 2,
                        'rounded-br-3xl': i === 3,
                    }"
                    draggable="true"
                    loading="lazy"
                    :src="(image.thumbnails && image.thumbnails.find((t) => t.height == 900)) ? image.thumbnails.find(t=>t.height == 900).url : image.url" />

                <div v-if="i == 3 && images.length > 4" class="absolute h-full w-full bg-[#000000]/20 top-0 flex items-center justify-center text-center rounded-br-3xl">
                    <span class="text-[#ffffff] text-5xl">{{ images.length - 4 }}+</span>
                </div>
            </a>
        </template>
        </div>
    </div>
</template>

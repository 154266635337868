<script setup>
import { onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { useChannelFeed, useChannelPage } from '@/composables/channel';
import { collectionV2 as collection } from '@/composables/resource';
import { useStore } from '@/store';

const router = useRouter();
const route = useRoute();
const store = useStore();
const page = useChannelPage(route.params.handle);
const channel = page.channel;
const feed = useChannelFeed(route.params.handle);

const members = collection({});

onMounted(() => {
    channel.fetchOnce({
        onsuccess: () => {
            members.setUrl(`/api/channels/${channel.data.id}/members`);

            if (channel.data.user_membership && channel.data.user_membership.status == store.state.app.enums.member_status.APPROVED) {
                feed.posts.fetchOnce();
                members.fetchOnce();
            }
        }
    });

    if (channel.filled()) {
        members.setUrl(`/api/channels/${channel.data.id}/members`);

        if (channel.data.user_membership && channel.data.user_membership.status == store.state.app.enums.member_status.APPROVED) {
            feed.posts.fetchOnce();
            members.fetchOnce();
        }
    }
});
</script>

<template>
    <RouterView v-slot="{ Component }">
        <Component :is="Component" :channel="channel" :posts="feed.posts" :feed="feed" :members="members" />
    </RouterView>
</template>

import { onMounted } from "vue";

import { collection } from "@/composables/resource";
import { useAjaxForm } from "@/utils/form";
import { useStore } from "@/store";

let broadcast = null;

export const useNotifications = () => {
    const notifications = collection('/api/notifications', 'my-notifications');
    const form = useAjaxForm();
    const clearUnreadForm = useAjaxForm();
    const store = useStore();

    onMounted(() => {
        notifications.fetchOnce();

        if (!broadcast) {
            broadcast = new BroadcastChannel('notification-channel');
            broadcast.onmessage = function() {
                if (store.state.user) {
                    store.state.user.unread_notifications_count++;
                    notifications.fetch();
                }
            };
        }
    });

    return {
        get collection() {
            return notifications;
        },
        clearUnreadNotificationsCount() {
            return clearUnreadForm.delete('/api/me/unread-notifications-count');
        },
        markAsRead(id) {
            return form.post(`/api/notifications/${id}/read`, {
                onSuccess: () => {
                    const notification = notifications.data.filter((notification) => notification.id === id)[0];

                    if (notification) {
                        notification.read = true;
                    }
                }
            });
        }
    }
}

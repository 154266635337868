import { markRaw, onMounted, reactive, ref } from 'vue';
import { collectionV2 } from '@/composables/resource';
import { useStore } from '@/store';
import { mem_store } from '@/utils/cache';
import { usePostListener } from '@/composables/listeners';
import echo from '@/plugins/echo';

import UserConnectSuggestions from '@/components/User/UserConnectSuggestions.vue';

let globalChannel = null;

export const useMyFeed = () => {
    const store = useStore();
    const key = (store.state.user?.id ?? '') + ':feed';
    const cache = mem_store('feed');

    const feed = cache.remember(key, () => {

        const posts = collectionV2({
            url: '/api/posts',
            params: {
                view: 1,
            },
            transformCb: (data) => {
                return injectSlot(data);
            }
        });

        const suggestions = collectionV2({
            url: '/api/user-connect-suggestions',
        });

        const injectSlot = (data) => {
            const suggestionInjected = posts.data.find(post => post.__client_meta?.injected && post.type === 'suggestion');

            if (!suggestionInjected) {
                const start = 4;

                data.splice(start, 0, {
                    type: 'suggestion',
                    component: markRaw(UserConnectSuggestions),
                    props: {
                        collection: suggestions,
                    },
                    __client_meta: {
                        injected: true,
                    }
                });
            }

            return data;
        }

        const removePost = (postId) => {
            if (!posts.fetched) {
                return;
            }

            const post = posts.data.find(post => post.id === postId);

            if (!post) {
                return;
            }

            post.deleted = true;

            setTimeout(() => {
                posts.data.splice(posts.data.indexOf(post), 1);
            }, 5000);
        }

        const onPostDeleted = (event) => {
            removePost(event.id);
        }

        const onPostHidden = (event) => {
            removePost(event.id);
        }

        const postListener = usePostListener((event) => {
            return posts.data.find(post => post.id === event.data.id);
        });

        onMounted(() => {
            postListener.listen();

            if (!globalChannel) {
                globalChannel = echo.channel('global');
                globalChannel.listen('.post:deleted', onPostDeleted);
                globalChannel.listen('.post:hidden', onPostHidden);
            }

            posts.fetchOnce();
            suggestions.fetchOnce();
        });

        return reactive({
            posts,
            suggestions,
        });
    });

    return feed;
}

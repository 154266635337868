<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_944_3050)">
            <path d="M11.25 3C12.6406 3 13.9882 3.26953 15.2929 3.80859C15.8554 4.03516 16.4179 4.20703 16.9804 4.32422C17.5429 4.44141 18.1328 4.5 18.75 4.5H24V15H19.1132C18.6054 15 18.1289 15.0938 17.6836 15.2812C17.2382 15.4688 16.832 15.7422 16.4648 16.1016L9.17574 23.3789C8.98043 23.5742 8.78511 23.7266 8.5898 23.8359C8.39449 23.9453 8.15621 24 7.87496 24C7.61714 24 7.37496 23.9531 7.14839 23.8594C6.92183 23.7656 6.72261 23.6289 6.55074 23.4492C6.37886 23.2695 6.24605 23.0703 6.1523 22.8516C6.05855 22.6328 6.00777 22.3906 5.99996 22.125C5.99996 21.4922 6.07027 20.8906 6.21089 20.3203C6.35152 19.75 6.55855 19.1797 6.83199 18.6094C6.98824 18.2734 7.12105 17.9336 7.23043 17.5898C7.3398 17.2461 7.41402 16.8828 7.45308 16.5H2.24996C1.94527 16.5 1.65621 16.4414 1.38277 16.3242C1.10933 16.207 0.871051 16.0469 0.667926 15.8438C0.464801 15.6406 0.300738 15.4023 0.175738 15.1289C0.0507382 14.8555 -0.00785558 14.5625 -4.30836e-05 14.25C-4.30836e-05 14 0.0390194 13.7617 0.117144 13.5352L3.11714 4.53516C3.27339 4.07422 3.54683 3.70312 3.93746 3.42188C4.32808 3.14062 4.76558 3 5.24996 3H11.25Z" fill="currentColor"/>
        </g>
        <defs>
            <clipPath id="clip0_944_3050">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { RouterLink } from 'vue-router';
import { markRaw } from 'vue';

import { useStore } from '@/store';
import { getDeviceType } from '@/utils';

import Avatar from '@/components/Avatar.vue';
import Badge from '@/components/Badge.vue';
import CreatePostModal from '@/components/Post/CreatePostModal.vue';
import DropdownMenu from '@/components/DropdownMenu/DropdownMenu.vue';
import DropdownMenuTrigger from '@/components/DropdownMenu/DropdownMenuTrigger.vue';
import Fixed from '@/components/Fixed.vue';
import IconBell from '@/components/Icons/Bell.vue';
import IconBellOutline from '@/components/Icons/BellOutline.vue';
import IconHashtagBox from '@/components/Icons/HashtagBox.vue';
import IconHashtagBoxOutline from '@/components/Icons/HashtagBoxOutline.vue';
import IconHomeVariant from '@/components/Icons/HomeVariant.vue';
import IconHomeVariantOutline from '@/components/Icons/HomeVariantOutline.vue';
import IconLogout from '@/components/Icons/Logout.vue';
import IconPencilPlusOutline from '@/components/Icons/PencilPlusOutline.vue';
import IconMessageText from '@/components/Icons/MessageText.vue';
import IconMessageTextOutline from '@/components/Icons/MessageTextOutline.vue';
import IconMoreCircleOutline from '@/components/Icons/MoreCircleOutline.vue';
import IconSettingsOutline from '@/components/Icons/SettingsOutline.vue';
import IconListStatus from '@/components/Icons/ListStatus.vue';
import IconAboutCircleOutline from '@/components/Icons/AboutCircleOutline.vue';
import IconShieldLockOutline from '@/components/Icons/ShieldLockOutline.vue';
import IconUsersGroupOutline from '@/components/Icons/UsersGroup.vue';
import ProfileLink from '@/components/Link/ProfileLink.vue';
import IconShare from '@/components/Icons/Share.vue';
import { useToast } from '@/plugins/toast';

const store = useStore();
const toast = useToast();
const showPostModal = ref(false);
const isDarkMode = ref(false);
const moreItems = ref([
    {
        to: { name: 'connections.index' },
        icon: markRaw(IconUsersGroupOutline),
        label: 'Connections',
    },
    {
        to: { name: 'settings.index' },
        icon: markRaw(IconSettingsOutline),
        label: 'Settings',
    },
    {
        to: { name: 'guidelines' },
        icon: markRaw(IconListStatus),
        label: 'Community Guidelines',
    },
    {
        to: { name: 'about' },
        icon: markRaw(IconAboutCircleOutline),
        label: 'About Corperland',
    },
    {
        to: { name: 'privacy-policy' },
        icon: markRaw(IconShieldLockOutline),
        label: 'Privacy Policy',
    },
    {
        icon: markRaw(IconShare),
        label: store.state.user.is_ambassador ? 'Referral Link' : 'Share/Invite Friends',
        onSelect: () => {
            const referralCode = store.state.user.referral_code || '';
            const ambassadorReferralLink = referralCode ? `${import.meta.env.VITE_APP_URL}/amb/${referralCode}` : import.meta.env.VITE_APP_URL;
            const linkToCopy = store.state.user.is_ambassador ? ambassadorReferralLink : import.meta.env.VITE_APP_URL;

            navigator.clipboard.writeText(linkToCopy).then(() => {
                const message = store.state.user.is_ambassador
                    ? 'Referral link copied.'
                    : 'Share link copied.';
                toast.info(message);
            }).catch((error) => console.error('Failed to copy link:', error));
        }
    },
    {
        to: { name: 'logout' },
        icon: markRaw(IconLogout),
        label: 'Logout',
    },
]);

const checkDarkMode = () => {
  isDarkMode.value = document.documentElement.getAttribute('data-dark-mode') === 'true';
};

onMounted(() => {
  checkDarkMode();
  const observer = new MutationObserver(() => {
    checkDarkMode();
  });
  observer.observe(document.documentElement, { attributes: true, attributeFilter: ['data-dark-mode'] });
});
</script>

<style lang="postcss">
.nav {
    height: 60px;
    @apply w-full px-2;

    @screen md {
        width: 60px;
        @apply h-screen;
    }

    @screen xl {
        width: 250px;
        @apply px-10;
    }
}
.nav {
    border: 1px solid red;
    @apply bg-white text-white-foreground shrink-0 border-white-foreground/10;

    @media (max-width: 767.9px) {
        box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.25);
    }

    @screen md {
        @apply flex items-center flex-col;
    }

    @screen xl {
        @apply items-start border-l border-t;
    }

    .nav__items {
        @apply flex items-center justify-between gap-4 h-full;
        @screen md {
            @apply flex-col justify-start items-start h-auto gap-8 mt-16;
        }
        @screen xl {
            @apply w-full;
        }
    }

    .nav__item {
        @apply grow md:w-full;
    }

    .nav__menu {
        @apply flex flex-col items-center text-white-foreground text-left w-full;
        @screen md {
            @apply flex-row gap-4;
        }
    }

    .nav__menu[data-active=true] .nav__icon, .nav__menu[data-active=true] .nav__text {
        @apply text-primary font-bold;
    }

    .nav__icon {
        width: 28px;
        height: 28px;
        @apply transition-colors duration-1000;
        @screen md {
            width: 32px;
            height: 32px;
        }
    }

    .nav__text {
        @apply text-2xs transition-colors duration-1000 font-medium;
        @apply md:hidden;

        @screen xl {
            @apply block text-base leading-none;
        }
    }
}
</style>

<template>
    <Teleport to="body" :disabled="$store.state.deviceType !== 'mobile'">
        <Fixed
            :to="() => getDeviceType() === 'mobile' ? 'bottom' : 'top'"
            :right="() => getDeviceType() === 'mobile' ? '0' : 'auto'"
        >
            <nav class="nav">
                <RouterLink v-if="$store.state.deviceType !== 'mobile'" class="block mt-16" :to="{ name: 'feed' }">
                    <img class="xl:hidden" src="@images/static/icon-32x32.svg" alt="site icon" />
                    <img v-if="isDarkMode" class="hidden xl:block" src="@images/static/logo-dark.svg" alt="site logo" />
                    <img v-if="!isDarkMode" class="hidden xl:block" src="@images/static/logo.svg" alt="site logo" />
                </RouterLink>
                <ul class="nav__items">
                    <li class="nav__item">
                        <RouterLink :to="{ name: 'feed' }" class="nav__menu" :data-active="$route.name === 'feed'">
                            <component :is="$route.name === 'feed' ? IconHomeVariant : IconHomeVariantOutline" class="nav__icon" />
                            <span class="nav__text">Feed</span>
                        </RouterLink>
                    </li>
                    <li class="nav__item">
                        <RouterLink to="/channels" class="nav__menu" :data-active="$route.path === '/channels'">
                            <component :is="$route.path === '/channels' ? IconHashtagBox : IconHashtagBoxOutline" class="nav__icon" />
                            <span class="nav__text">Channels</span>
                        </RouterLink>
                    </li>
                    <li class="nav__item md:order-last">
                        <button type="button" class="nav__menu" :data-active="false" @click="showPostModal = true">
                            <IconPencilPlusOutline class="nav__icon" />
                            <span class="nav__text">Post</span>
                            <CreatePostModal @postPublished="() => {
                                showPostModal = false;
                            }" :open="showPostModal" @update:open="(value) => showPostModal = value" />
                        </button>
                    </li>
                    <li class="nav__item">
                        <RouterLink to="/conversations" class="nav__menu" :data-active="$route.path === '/conversations'">
                            <div class="relative">
                                <component :is="$route.path === '/conversations' ? IconMessageText : IconMessageTextOutline" class="nav__icon" />
                                <Badge class="absolute top-0 -right-1 xl:hidden" v-if="$store.state.user.unread_conversations_count" />
                            </div>
                            <span class="nav__text">Chats</span>
                        </RouterLink>
                    </li>
                    <li class="nav__item">
                        <RouterLink to="/notifications" class="nav__menu" :data-active="$route.path === '/notifications'">
                            <div class="relative">
                                <component :is="$route.path === '/notifications' ? IconBell : IconBellOutline" class="nav__icon relative" />
                                <Badge class="absolute top-0 -right-1 xl:hidden" :count="$store.state.user.unread_notifications_count" />
                            </div>
                            <span class="nav__text">Notifications</span>
                            <Badge class="hidden xl:block -ml-2" :count="$store.state.user.unread_notifications_count" />
                        </RouterLink>
                    </li>
                    <li v-if="$store.state.user.profile" class="nav__item order-last hidden md:flex">
                        <ProfileLink :profile="$store.state.user.profile" class="nav__menu">
                            <Avatar class="nav__icon" size="custom" :src="$store.state.user.profile.avatar?.url" :fallback-from="$store.state.user.name" />
                            <span class="nav__text">Profile</span>
                        </ProfileLink>
                    </li>
                    <li class="grow nav__item hidden md:flex order-last"></li>
                    <li class="nav__item hidden md:flex order-last">
                        <DropdownMenu :items="moreItems" content-align="start" content-side="top" :side-offset="1">
                            <DropdownMenuTrigger class="nav__menu">
                                <IconMoreCircleOutline class="nav__icon" />
                                <span class="nav__text">More</span>
                            </DropdownMenuTrigger>
                        </DropdownMenu>
                    </li>
                </ul>
            </nav>
        </Fixed>
    </Teleport>
</template>

<script setup>
const emit = defineEmits(['finished-typing', 'typing']);
const model = defineModel();
const props = defineProps({
    lowercase: {
        type: Boolean,
        default: false,
        required: false,
    },
    debounce: {
        type: Number,
        default: 1000,
        required: false,
    },
});

let emitFinishedTyping = null;

const onInput = (e) => {
    clearTimeout(emitFinishedTyping);

    if (props.lowercase) {
        e.target.value = e.target.value.toLowerCase();
    }

    emit('typing', e.target.value);

    emitFinishedTyping = setTimeout(() => {
        emit('finished-typing', e.target.value);
    }, props.debounce);
}
</script>

<template>
        <input
        v-model="model"
        v-on:input="onInput"
        class="
        disabled:opacity-70 disabled:bg-gray
        block w-full rounded-lg focus:outline-none
        placeholder:text-black/40
        border-0 ring-1 ring-inset ring-black/20
        focus:ring-2 focus:ring-black/30
        invalid:focus:ring-rose-200 invalid:focus:border-rose-600
        group-invalid:focus:ring-rose-200 group-invalid:focus:border-rose-600 bg-white text-white-foreground
        text-slate-500" />
</template>

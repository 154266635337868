<script setup>
import { RouterLink } from 'vue-router';

import DropdownMenuRoot from '@/components/DropdownMenu/DropdownMenuRoot.vue';
import DropdownMenuPortal from '@/components/DropdownMenu/DropdownMenuPortal.vue';
import DropdownMenuContent from '@/components/DropdownMenu/DropdownMenuContent.vue';
import DropdownMenuItem from '@/components/DropdownMenu/DropdownMenuItem.vue';
import DropdownMenuSeparator from '@/components/DropdownMenu/DropdownMenuSeparator.vue';
import { computed } from 'vue';

const props = defineProps({
    sideOffset: {
        type: Number,
        required: false,
    },
    items: {
        required: false,
        type: Object,
    },
    contentAlign: {
        required: false,
        type: String
    },
    contentSide: String
});

const itemSelected = (e, item) => {
    if (typeof item.onSelect === 'function') {
        item.onSelect(e);
    }
}

const shouldShow = (item) => {
    if ('hide' in item) {
        return typeof item.hide === 'function' ? !item.hide() : Boolean(!item.hide);
    }

    if ('show' in item) {
        return typeof item.show === 'function' ? item.show() : Boolean(item.show);
    }

    return true;
}

const visibleItems = computed(() => {
    return props.items.filter((item) => shouldShow(item));
});

</script>

<template>
    <DropdownMenuRoot :default-open="false">
        <slot :visibleItems="visibleItems" />
        <DropdownMenuPortal>
            <DropdownMenuContent
                :align="contentAlign"
                :side-offset="sideOffset"
                :side="contentSide"
            >
                <ul v-if="items.length > 0">
                    <li v-for="item, index in visibleItems" :key="index">
                        <DropdownMenuSeparator v-if="index !== 0" />
                        <DropdownMenuItem :disabled="item.disabled" :as="'to' in item ? RouterLink : item.as" v-bind="item.attrs" :to="'to' in item ? item.to : undefined" v-on:select="(e) => itemSelected(e, item)">
                            <component v-if="item.icon" height="20" width="20" :is="item.icon" />
                            <span>{{  item.label }}</span>
                        </DropdownMenuItem>
                    </li>
                </ul>
            </DropdownMenuContent>
        </DropdownMenuPortal>
    </DropdownMenuRoot>
</template>

<script setup>
import { onMounted, ref } from 'vue';

const props = defineProps({
    collection: {
        required: false,
    },
    count: {
        type: Number,
        default: 15,
    },
    ignoreHeight: {
        type: Boolean,
        default: false,
    },
    minimal: {
        type: Boolean,
        default: false,
    },
});

const skeleton = ref(null);

onMounted(() => {
    if (skeleton.value && !props.ignoreHeight) {
        skeleton.value.style.setProperty('height', `calc(100vh - ${skeleton.value.offsetTop + 60}px)`, 'important');
    }
});
</script>

<template>
    <div ref="skeleton" class="overflow-hidden flex flex-col gap-6" v-if="!collection || (!collection.fetched && collection.fetching)">
        <div v-for="_ in count" class="flex flex-col gap-4 rounded-md bg-white w-full animate-pulse p-4" :key="_">
            <div class="flex gap-2 items-center">
                <div class="w-10 h-10 rounded-full bg-white-foreground/20"></div>
                <div class="flex flex-col gap-1">
                    <div class="w-[200px] h-[16px] rounded-full bg-white-foreground/20"></div>
                    <div class="w-[100px] h-[12px] rounded-full bg-white-foreground/20"></div>
                </div>
            </div>
            <div class="flex flex-col gap-1" v-if="!minimal">
                <div class="w-full h-3 rounded-full bg-white-foreground/20"></div>
                <div class="w-full h-3 rounded-full bg-white-foreground/20"></div>
                <div class="w-full h-3 rounded-full bg-white-foreground/20"></div>
                <div class="w-[80%] h-3 rounded-full bg-white-foreground/20"></div>
            </div>
        </div>
    </div>
</template>

import { useEmitter } from "@/plugins/emitter";

export const usePostListener = (getPost) => {
    const emitter = useEmitter();

    const onUpdated = (event) => {
        const post = getPost(event);

        if (!post) {
            return;
        }

        if (event.data?.id !== post.id) {
            return;
        }

        Object.keys(event.data).forEach((key) => {
            post[key] = event.data[key];
        });
    }

    return {
        listen() {
            emitter.off('.post:updated', onUpdated);
            emitter.on('.post:updated', onUpdated);
        },
        off() {
            emitter.off('.post:updated', onUpdated);
        }
    }
}

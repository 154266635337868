<script setup>
import IconLoader from '@/components/Icons/Loader.vue';
import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogRoot,
  AlertDialogTitle,
} from 'radix-vue';
import { onBeforeMount, ref, getCurrentInstance } from 'vue';

defineEmits(['confirmed', 'cancelled']);
const open = defineModel('open');
const instance = getCurrentInstance();

const props = defineProps({
    danger: {
        required: false,
        default: false,
        type: Boolean,
    },
    hideCancel: {
        required: false,
        default: false,
        type: Boolean,
    },
    title: {
        required: true,
        type: String,
    },
    description: {
        required: false,
        type: String,
    },
    confirmText: {
        required: false,
        type: String,
        default: 'Okay',
    },
    cancelText: {
        required: false,
        type: String,
        default: 'Cancel'
    },
    onConfirm: {
        required: false,
        type: Function,
    },
    disabled: {
        default: false,
        type: Boolean,
    },
});

const cancel = () => {
    open.value = false;
    instance.emit('cancelled');
}

const processing = ref(false);

const alert = {
    setProcessing(value) {
        processing.value = value;
    }
}

const confirm = () => {
    if (props.onConfirm) {
        processing.value = true;
        props.onConfirm();
        processing.value = false;
    } else {
        instance.emit('confirmed', alert);
    }
}

onBeforeMount(() => {
    processing.value = false;
});
</script>

<template>
    <AlertDialogRoot :open="open">
        <AlertDialogPortal>
            <AlertDialogOverlay class="bg-black/70 data-[state=open]:animate-overlayShow fixed inset-0 z-[100]" />
            <AlertDialogContent
            class="flex flex-col gap-2 z-[100] data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[500px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none"
            >
                <AlertDialogTitle v-if="title" class="text-white-foreground m-0 text-lg font-semibold">
                    <div v-html="title"></div>
                </AlertDialogTitle>
                <AlertDialogDescription v-if="description" class="text-white-foreground leading-normal">
                    <div v-html="description"></div>
                </AlertDialogDescription>
                <slot></slot>
                <div class="flex justify-end gap-[25px]">
                    <AlertDialogCancel
                        v-if="!hideCancel"
                        @click="cancel"
                        class="text-white-foreground/70 bg-white-foreground/5 hover:bg-white-foreground/10 focus:shadow-white-foreground/20 inline-flex h-9 items-center justify-center rounded-md px-4 font-semibold leading-none outline-none focus:shadow-[0_0_0_2px]"
                    >
                    {{ cancelText }}
                    </AlertDialogCancel>
                    <AlertDialogAction
                        :disabled="processing || disabled"
                        :class="[
                            danger ? 'text-danger-foreground bg-danger' : 'text-primary-foreground bg-primary'
                        ]"
                        class="disabled:opacity-60 inline-flex h-9 items-center justify-center rounded-md px-4 font-semibold leading-none outline-none focus:shadow-[0_0_0_2px]"
                        @click="confirm"
                        >
                        <IconLoader v-if="processing" width="28" height="28" />
                        {{ confirmText }}
                    </AlertDialogAction>
                </div>
            </AlertDialogContent>
        </AlertDialogPortal>
    </AlertDialogRoot>
</template>

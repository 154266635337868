<script setup>
import { TabsList, TabsRoot, TabsTrigger, TabsContent, TabsIndicator } from 'radix-vue';
import { onBeforeMount, onMounted, ref } from 'vue';

import { useJoinedChannels } from '@/composables/channel';

import AppButton from '@/components/Button/Button.vue';
import Modal from '@/components/Modals/Modal.vue';
import PostForm from '@/components/Post/PostForm.vue';

defineEmits(['postPublished']);

const props = defineProps({
    defaultChannel: {
        type: Object,
        required: false,
    }
});

const activeTab = ref('');
const channel = ref(null);
const selectedChannel = ref(null);
const channels = useJoinedChannels();

onBeforeMount(() => {
    channel.value = null;
    selectedChannel.value = null;
    activeTab.value = 'post';

    if (props.defaultChannel) {
        selectedChannel.value = props.defaultChannel;
        channel.value = selectedChannel.value;
    }
});

onMounted(() => {
    channels.fetchOnce();
})
</script>

<template>
    <Modal>
        <div class="md:w-[400px] min-h-[400px] bg-white text-white-foreground">
            <div v-if="!channel">
                <h1 class="font-medium text-lg">
                    Select One Channel
                </h1>
                <p class="text-sm leading-tight">
                    You can post a message or ask a question in channels that you are a member of.
                </p>
                <div v-if="channels.data.length">
                    <ul class="flex flex-col mt-4 max-h-[350px] overflow-auto">
                        <li v-for="channel in channels.data.filter((c) => c.posting_disabled == false || c.user_membership?.role_abilities['posts:bypass-restriction'])" :key="channel.id">
                            <button type="button"
                                @click.prevent="selectedChannel = channel"
                                :data-active="selectedChannel?.id === channel.id"
                                class="p-3 flex flex-col w-full relative data-[active=true]:bg-primary/5 rounded-md">
                                <span class="font-medium">{{ channel.name}}</span>
                                <span class="opacity-70 text-sm">#{{ channel.handle }}</span>
                                <div class="
                                    absolute right-2 h-6 w-6 rounded-full border
                                    border-white-foreground border-opacity-20
                                    top-1/3 flex items-center justify-center
                                "
                                >
                                    <div :data-active="selectedChannel?.id === channel.id" class="h-3 w-3 data-[active=true]:bg-primary rounded-full"></div>
                                </div>
                            </button>
                        </li>
                    </ul>
                    <AppButton @click.prevent="channel = selectedChannel" type="button" :disabled="!selectedChannel" class="mt-6 ml-auto block" color="primary" compact>
                        Select
                    </AppButton>
                </div>
                <div v-else class="h-[350px]"></div>
            </div>
            <!-- TABS -->
            <TabsRoot v-else :model-value="activeTab" @update:model-value="(value) => activeTab = value">
                <div class="bg-white text-white-foreground">
                    <TabsList class="relative shrink-0 flex justify-evenly gap-4 border-b border-black/20" aria-label="Navigate Channel">
                        <TabsIndicator class="absolute h-[3px] w-[--radix-tabs-indicator-size] left-0 bottom-0 translate-x-[--radix-tabs-indicator-position] rounded-full transition-[width,transform] bg-primary" />

                        <TabsTrigger class="items-center justify-center select-none cursor-pointer outline-none py-1 data-[state=active]:text-primary" value="post">
                            Create Post
                        </TabsTrigger>
                        <TabsTrigger class="items-center justify-center select-none cursor-pointer outline-none py-1 data-[state=active]:text-primary" value="question">
                            Ask Question
                        </TabsTrigger>
                    </TabsList>
                </div>
                <div class="pt-4">
                    <TabsContent value="post">
                        <PostForm @back="channel = null" :channel="channel" @postPublished="(post) => $emit('postPublished', post)" />
                    </TabsContent>
                    <TabsContent value="question">
                        <PostForm @back="channel = null" :channel="channel" question @postPublished="(post) => $emit('postPublished', post)" />
                    </TabsContent>
                </div>
            </TabsRoot>
        </div>
    </Modal>
</template>

<script setup>
import Alert from '@/components/Modals/Alert.vue';
import IconLoading from '@/components/Icons/Loader.vue';
import { useAjaxForm } from '@/utils/form';
import { getCurrentInstance, onBeforeMount } from 'vue';
import { ref } from 'vue';

defineEmits(['action:success', 'button:click']);

const props = defineProps({
    as: {
        default: 'button',
    },
    color: {
        default: 'transparent',
        type: String,
        required: false,
    },
    lowercase: {
        default: false,
        type: Boolean,
        required: false,
    },
    compact: {
        default: false,
        type: Boolean,
        required: false,
    },
    capitalize: {
        default: false,
        type: Boolean,
        required: false,
    },
    uppercase: {
        default: true,
        type: Boolean,
        required: false,
    },
    full: {
        default: false,
        type: Boolean,
        required: false,
    },
    roundedFull: {
        default: true,
        type: Boolean,
        required: false,
    },
    action: {
        required: false,
        default: null,
        type: String,
    },
    actionData: {
        required: false,
        default: null,
        type: Object,
    },
    actionMethod: {
        required: false,
        default: 'post',
        type: String,
    },
    noShadow: {
        default: false,
        type: Boolean,
        required: false,
    },
    loading: {
        default: false,
        type: Boolean,
        required: false,
    },
    bindAlert: {
        type: Object,
        required: false,
    }
});
const colorVariants = {
    primary: 'bg-primary text-primary-foreground',
    white: 'bg-white text-white-foreground',
    gray: 'bg-gray text-gray-foreground',
    danger: 'bg-danger text-danger-foreground',
    warning: 'bg-warning text-warning-foreground',
    success: 'bg-success text-success-foreground',
    transparent: 'bg-transparent',
    'primary-border': 'bg-transparent text-primary border border-primary',
    'white-border': 'bg-transparent text-white border border-white',
    'gray-border': 'bg-transparent text-white-foreground border border-white-foreground/20',
    'danger-border': 'bg-transparent text-danger border border-danger',
    'warning-border': 'bg-transparent text-warning border border-warning',
    'success-border': 'bg-transparent text-success border border-success',
    'primary-light': 'bg-primary-light text-primary',
    'danger-light': 'bg-danger-light text-danger',
    'warning-light': 'bg-warning-light text-warning',
    'success-light': 'bg-success-light text-success',
};

let form = null;

const alertOpen = ref(false);

onBeforeMount(() => {
    if (props.action) {
        form = useAjaxForm(props.actionData);
    }
});

const instance = getCurrentInstance();

const submitForm = (cb) => {
    form[props.actionMethod](props.action, {
        onSuccess: (response) => {
            instance.emit('action:success', response.data);
            if (cb) {
                cb();
            }
        },
    });
}

const alertConfirmed = (alert) => {
    alert.setProcessing(true);
    submitForm(() => alertOpen.value = false);
}

const startSubmit = (e) => {
    e.preventDefault();

    if (props.bindAlert) {
        alertOpen.value = true;
    } else {
        submitForm();
    }
};
</script>

<template>
    <component
        v-on:click="(e) => form ? startSubmit(e) : $emit('button:click', e)"
        :disabled="loading || (form && form.processing)"
        :is="as"
        class="font-semibold disabled:opacity-60 flex items-center justify-center gap-1 select-none"
        :class="[
            colorVariants[color],
            compact ? 'h-8 text-[90%] px-2' : 'h-10 px-4',
            lowercase ? 'lowercase' : (capitalize ? 'capitalize' : (uppercase ? 'uppercase' : '')),
            full ? 'w-full' : (!roundedFull ? 'w-auto' : (compact ? 'min-w-8' : 'min-w-12')),
            roundedFull ? 'rounded-full' : 'rounded-md',
            noShadow ? 'shadow-none' : 'hover:shadow-md',
        ]">
        <IconLoading v-if="loading" :class="{
            'size-7': !compact,
            'size-5': compact,
        }" />
        <slot></slot>
        <Alert v-if="form && bindAlert" v-bind="bindAlert" v-model:open="alertOpen" @confirmed="alertConfirmed" />
    </component>
</template>

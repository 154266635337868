<script setup>
import { computed, markRaw, onBeforeMount, reactive } from 'vue';
import { RouterLink, RouterView, useRoute, useRouter } from 'vue-router';

import { useProfileResource, collection } from '@/composables/resource';
import { PostType } from '@/enums';
import { useStore } from '@/store';
import { shouldShowItem } from '@/utils';

import AppButton from '@/components/Button/Button.vue';
import Avatar from '@/components/Avatar.vue';
import Content from '@/views/_partials/Content.vue';
import Header from '@/views/_partials/header/Header.vue';
import HeaderTitle from '@/views/_partials/header/HeaderTitle.vue';
import HeaderMenu from '@/views/_partials/header/HeaderMenu.vue';
import IconMessageTextOutline from '@/components/Icons/MessageTextOutline.vue';
import IconUsersGroupOutline from '@/components/Icons/UsersGroupOutline.vue';
import IconUserMultipleAddOutline from '@/components/Icons/UserMultipleAddOutline.vue';
import IconUserAdd from '@/components/Icons/UserAdd.vue';
import IconUserCheck from '@/components/Icons/UserCheck.vue';
import IconUserRemove from '@/components/Icons/UserRemove.vue';
import LineClamp from '@/components/LineClamp.vue';
import PostList from '@/components/Post/PostList.vue';
import SettingsOutline from '@/components/Icons/SettingsOutline.vue';
import Skeleton from '@/components/Renderer/Skeleton.vue';
import TabsList from '@/components/Tabs/TabsList.vue';
import TabsRoot from '@/components/Tabs/TabsRoot.vue';
import TabsTrigger from '@/components/Tabs/TabsTrigger.vue';
import TabsContent from '@/components/Tabs/TabsContent.vue';
import { useAjaxForm } from '@/utils/form';

const props = defineProps({
    pageData: {
        type: Object,
        required: false,
    },
    username: {
        type: String,
        required: true,
    }
});

const state = reactive({
    activeTab: 'posts',
});

const store = useStore();
const router = useRouter();
const route = useRoute();
const is_current_user = store.state.user && store.state.user.profile.username === props.username;

const resource = useProfileResource(props.username);
const resourceRelations = reactive({
    posts: collection({
        url: '/api/posts',
    }, is_current_user ? 'myPosts' : false),
    questions: collection({
        url: '/api/posts',
    }, is_current_user ? 'myQuestions' : false),
});

if (props.pageData?.data) {
    resource.fill(props.pageData);
} else if (is_current_user) {
    resource.fill({ data: store.state.user});
}

const setRelations = () => {
    resourceRelations.posts.filter = {
        user_id: resource.data.id,
        view: 2,
        type: PostType.POST,
    };

    resourceRelations.questions.filter = {
        user_id: resource.data.id,
        view: 2,
        type: PostType.QUESTION,
    };

    if (!resourceRelations.posts.fetched) {
        resourceRelations.posts.fetch();
    }

    if (!resourceRelations.questions.fetched) {
        resourceRelations.questions.fetch();
    }
}

const cancelConnectionForm = useAjaxForm();


const startChatForm = useAjaxForm({
    user: '',
});

const startChat = (userId) => {
    startChatForm.user = userId;

    startChatForm.post('/api/conversations', {
        onSuccess: (response) => {
            router.push({
                name: 'conversations.show',
                params: {
                    id: response.data.data.id,
                },
            });
        },
    });
}

const menuItems = computed(() => {
    return [
        {
            label: 'Connections',
            icon: markRaw(IconUsersGroupOutline),
            to: { name: 'connections.index' },
            show: store.state.user && is_current_user,
        },
        {
            label: 'Referrals',
            icon: markRaw(IconUserMultipleAddOutline),
            to: '/referrals',
            show: store.state.user && store.state.user.is_ambassador,
        }
    ];
});

const showMenu = computed(() => {
    return menuItems.value.filter(item => shouldShowItem(item)).length > 0;
});

onBeforeMount(() => {
    if (!resource.filled()) {
        resource.fetch({
            onsuccess: () => setRelations(),
        });
    } else {
        setRelations();
    }
});
</script>

<template>
    <Content class="bg-gray text-gray-foreground">
        <Header with-back-button>
            <HeaderTitle>
                Profile
            </HeaderTitle>
            <div class="grow"></div>

            <RouterLink v-if="is_current_user" class="ml-auto" :to="{ name: 'settings.edit-profile' }">
                <SettingsOutline class="size-6" />
                <span class="sr-only">Edit Profile</span>
            </RouterLink>

            <HeaderMenu v-if="showMenu" :items="menuItems" />
        </Header>

        <div class="bg-white text-white-foreground" v-if="!resource.filled() && resource.fetching()">
            <Skeleton :count="1" ignore-height />
        </div>

        <template v-if="resource.filled()">
            <div class="bg-white text-white-foreground p-4">
                <div class="flex flex-row gap-2 items-center">
                    <Avatar as-link size="xl" :src="resource.data.profile.avatar?.url" :image="resource.data.profile.avatar" :fallback-from="resource.data.name" />
                    <div class="flex-grow flex flex-col justify-center">
                        <h1 class="text-xl font-bold leading-none">{{ resource.data.name }}</h1>
                        <p class="leading-none text-sm opacity-70 mt-1">@{{ resource.data.profile.username }}</p>
                    </div>
                </div>

                <p class="leading-tight mt-3">
                    {{ resource.data.profile.type_label }}<br>
                </p>
                <span v-if="resource.data.profile.type == 1 || resource.data.profile.type == 3">
                    {{ resource.data.profile.type_details.batch_year }} Batch {{ resource.data.profile.type_details.batch }} Stream {{ resource.data.profile.type_details.batch_stream }}
                </span>
                <span v-if="resource.data.profile.type == 1 || resource.data.profile.type == 3">
                    <br>
                    {{ $store.state.app.location_options[resource.data.profile.type_details.batch_state] }}
                </span>
                <span v-if="resource.data.profile.school">
                    <br>
                    {{ $store.state.app.school_options[resource.data.profile.school] }}
                </span>

                <div class="mt-2" v-if="resource.data.profile.bio">
                    <h2 class="text-sm font-semibold">Bio</h2>
                    <LineClamp :lines="2">
                        <div class="text-sm" v-html="resource.data.profile.bio" />
                    </LineClamp>
                </div>

                <dl class="flex text-sm gap-4 mt-3">
                    <dt class="sr-only">Number of Posts</dt>
                    <dd class="flex gap-1">
                        <span class="font-medium">{{ resource.data.posts_count_abbrv }}</span>
                        <span class="opacity-70">Posts</span>
                    </dd>
                    <dd class="flex gap-1">
                        <span class="font-medium">{{ resource.data.questions_count_abbrv }}</span>
                        <span class="opacity-70">Questions</span>
                    </dd>
                </dl>
                <div v-if="!is_current_user" class="mt-4 flex gap-2 *:w-1/2 *:md:flex-grow-0">
                    <template v-if="!resource.connection">
                        <AppButton :as="RouterLink" :to="{ name: 'profiles.show.connect' }" type="button" color="primary" compact capitalize>
                            <IconUserAdd class="size-5" />
                            <span>Connect</span>
                        </AppButton>
                    </template>
                    <template v-else-if="!resource.connection.accepted">
                        <template v-if="resource.connection.recipient.id === store.state.user.id">
                            <AppButton
                                :action="`/api/user-connections/${resource.connection.id}`"
                                action-method="delete"
                                @action:success="() => {
                                    resource.connection = null;
                                    $toast.success('Request Declined');
                                }"
                                color="danger-border"
                                compact
                                capitalize>
                                <IconUserRemove class="size-5" />
                                <span>Decline</span>
                            </AppButton>
                            <AppButton
                                :action="`/api/user-connections/${resource.connection.id}/accept`"
                                action-method="post"
                                @action:success="(data) => {
                                    resource.connection = data.data;
                                    $toast.success('Connection Accepted');
                                }"
                                color="primary-border"
                                compact
                                capitalize>
                                <IconUserCheck class="size-5" />
                                <span>Accept</span>
                            </AppButton>
                        </template>
                        <AppButton
                            v-else
                            :action="`/api/user-connections/${resource.connection.id}`"
                            action-method="delete"
                            @action:success="() => {
                                resource.connection = null;
                                $toast.success('Request cancelled');
                            }"
                            color="gray-border"
                            compact
                            capitalize>
                            <IconUserRemove class="size-5" />
                            <span>Cancel Request</span>
                        </AppButton>
                    </template>
                    <template v-else>
                        <AppButton
                            :as="RouterLink"
                            :to="{ name: 'profiles.show.disconnect' }"
                            color="gray-border"
                            compact
                            capitalize>
                            <IconUserCheck class="size-5" />
                            <span>Connected</span>
                        </AppButton>
                    </template>


                    <AppButton @click="startChat(resource.data.id)" type="button" color="primary-border" compact capitalize>
                        <IconMessageTextOutline class="size-5" />
                        <span>Chat</span>
                    </AppButton>
                </div>
            </div>

            <TabsRoot :model-value="state.activeTab" @update:model-value="(value) => state.activeTab = value">
                <div class="pt-4 bg-white text-white-foreground">
                    <TabsList aria-label="Navigate Channel">
                        <TabsTrigger value="posts">
                            Posts
                        </TabsTrigger>
                        <TabsTrigger value="questions">
                            Questions
                        </TabsTrigger>
                    </TabsList>
                </div>
                <div class="pt-3 md:px-4">
                    <TabsContent value="posts">
                        <KeepAlive>
                            <PostList v-if="state.activeTab === 'posts' && resource.filled()" :collection="resourceRelations.posts" />
                        </KeepAlive>
                    </TabsContent>
                    <TabsContent value="questions">
                        <KeepAlive>
                            <PostList v-if="state.activeTab === 'questions' && resource.filled()" :collection="resourceRelations.questions" />
                        </KeepAlive>
                    </TabsContent>
                </div>
            </TabsRoot>
        </template>

        <RouterView v-slot="{ Component }">
            <component :is="Component" :resource="resource" />
        </RouterView>
    </Content>
</template>

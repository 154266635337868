<script setup>
import { onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

import { useAjaxForm } from "@/utils/form";
import { useLoginHandler } from "@/composables/actions";

import Error from '@/components/Form/Error.vue';
import FormButton from '@/components/Form/FormButton.vue';
import FormInput from '@/components/Form/FormInput.vue';

const router = useRouter();
const route = useRoute();
const loginHandler = useLoginHandler();
const form = useAjaxForm({
    email: route.query.email,
    credential: route.query.credential,
    password: '',
});

form.retrievesCsrf();

form.onSuccess((response) => {
    loginHandler.handle(response);
    router.push({ name: 'feed' });
});
</script>

<template>
    <div class="w-full mt-6">
        <form @submit.prevent="form.post('/auth/social')">
            <p class="text-center">
                To login as <span class="font-semibold">{{ route.query.email }}</span>, Please enter your password to link your account
            </p>

            <div class="mt-10">
                <FormInput :form="form" name="password" label="Password" type="password" />
            </div>

            <div class="mt-10">
                <FormButton :form="form" type="submit" full color="primary" roundedFull>Login</FormButton>
            </div>
        </form>
    </div>
</template>


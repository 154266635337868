<script setup>
import ConversationSidebar from '@/views/_partials/ConversationSidebar.vue';
import IndexContent from '@/views/conversations/_helpers/IndexContent.vue';
import RequestsContent from '@/views/conversations/_helpers/RequestsContent.vue';

defineProps({
    baseProps: Object,
});

const sidebarContent = {
    'requests': RequestsContent,
    'default': IndexContent,
}
</script>

<template>
    <ConversationSidebar v-if="$store.state.deviceType !== 'mobile'">
        <component :is="sidebarContent[$route.query.referer || 'default']" :baseProps="baseProps" />
    </ConversationSidebar>
</template>

import { createApp, h, inject } from "vue";
import Toast from "./Toast.vue";

const toastKey = Symbol('toast');

const createToastApp = () => {
    const app = createApp({
        data() {
            return {
                toasts: [],
            }
        },
        methods: {
            publish(toast) {
                this.toasts.push(toast);
            }
        },
        render() {
            return h(Toast, {
                toasts: this.toasts,
            });
        },
    });

    let instance;

    if (typeof window !== 'undefined') {
        const mountPoint = document.createElement('div');
        mountPoint.setAttribute('id', 'toast');
        document.body.appendChild(mountPoint);
        instance = app.mount(mountPoint);
    }

    return {
        publish: instance?.publish ?? (() => {}),
        success: (message) => {
            instance?.publish({
                title: message,
                color: 'success',
            });
        },
        error: (message) => {
            instance?.publish({
                title: message,
                color: 'danger',
            });
        },
        info: (message) => {
            instance?.publish({
                title: message,
                color: 'info',
            });
        },
        debug: (message) => {
            instance?.publish({
                title: message,
                color: 'info',
            });
        }
    };
}

export function useToast() {
    const toast = inject(toastKey);

    if (!toast) {
        throw new Error('useToast() is called without provider.');
    }

    return toast;
}

export function createToast() {
    return {
        install(app) {
            const toast = createToastApp();
            app.config.globalProperties.$toast = toast;
            app.provide(toastKey, toast);
        }
    }
}

import axios from '@/axios';
import echo from '@/plugins/echo';

let listeningForPush = false;
let listeningForUnreadConversationsUpdated = false;

const updateUserFromApi = (store) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axios.get('/api/me');
            const user = response.data.data;
            store.commit('user', user);
            localStorage.setItem('me', JSON.stringify(user));
            resolve();
        } catch (error) {
            if (error.response?.status === 401) {
                store.commit('user', null);
            }
            reject(error);
        } finally {
            store.commit('loading', false);
            store.commit('user_fetched_at', new Date().toISOString());
        }
    });
}

const setUserFromLocalStorage = async (store) => {
    const me = localStorage.getItem('me');

    if (me && me !== 'undefined') {
        const data = JSON.parse(me);
        updateUserFromApi(store);
        return store.commit('user', data);
    }

    if (store.state.user_fetched_at) {
        return;
    }

    store.commit('loading', true);

    try {
        await updateUserFromApi(store);
    } catch (error) {
        console.error(error);
    }
};

export const mustCreateProfile = ({ store, to }) => {
    if (store.state.user && !store.state.user.profile) {
        return {
            name: 'create-profile',
        };
    }
}

export const mustVerifyEmail = ({ store, to }) => {
    if (store.state.user && !store.state.user.email_verified_at) {
        return {
            name: 'verify-email',
            query: { redirect: to.fullPath },
        };
    }
}

export const auth = async ({ store, to }) => {
    if (!store.state.user && to.name !== 'login' && to.name !== 'feed') {
        return {
            name: 'login',
            query: { redirect: to.fullPath },
        };
    }
}

export const app = async ({ store, notification, router }) => {
    if (typeof window === 'undefined') {
        return true;
    }

    if (!store.state.user) {
        await setUserFromLocalStorage(store);
    }

    if (!store.state.notification_settings && store.state.user) {
        axios.get('/api/settings/notification').then((response) => {
            store.commit('notification_settings', response.data.data);
        });
    }

    if (store.state.user) {
        notification.refreshToken();
    }

    if (store.state.user && !listeningForUnreadConversationsUpdated) {
        listeningForUnreadConversationsUpdated = true;
        echo.private(`users.${store.state.user.id}`).listen('.unread-conversations:updated', (event) => {
            if (store.state.user) {
                store.state.user.unread_conversations_count = event.count;
            }
        });
    }

    if ('serviceWorker' in navigator) {
        if (!store.state.updating) {
            navigator.serviceWorker.getRegistration('/sw.js').then(async (registration) => {
                const update = await registration.update();

                if (!update?.waiting) {
                    return;
                }

                store.commit('updating', true);
                setTimeout(function () {
                    update.waiting.postMessage({ action: 'skipWaiting' });
                }, 1000);
            }).catch(error => {
                console.error('Error updating service worker', error);
            });
        }

        if (!listeningForPush) {
            listeningForPush = true;
            navigator.serviceWorker.addEventListener('message', (event) => {
                const eventData = event.data;

                if (eventData.type !== 'push') {
                    return;
                }

                if (eventData.data.data.notification_type === 'new-message' && !router.currentRoute.value.fullPath.startsWith('/conversations')) {
                    event.target.ready.then((registration) => {
                        registration.showNotification(eventData.data.notification.title, eventData.options);
                    });
                }
            });
        }
    }
}

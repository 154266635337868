<script setup>
import { markRaw, onBeforeMount, onMounted, reactive, computed } from 'vue';

import { collection } from '@/composables/resource';
import { useAjaxForm } from '@/utils/form';
import { useStore } from '@/store';
import { useToast } from '@/plugins/toast';
import { useNotifications } from '@/composables/notifications';

import Badge from '@/components/Badge.vue';
import Content from '@/views/_partials/Content.vue';
import DefaultTemplate from '@/components/Notifications/DefaultTemplate.vue';
import EmptyList from '@/components/Renderer/EmptyList.vue';
import IconBell from '@/components/Icons/Bell.vue';
import IconCheckAll from '@/components/Icons/CheckAll.vue';
import IconSettingsOutline from '@/components/Icons/SettingsOutline.vue';
import InfiniteScrollObserver from '@/components/Renderer/InfiniteScrollObserver.vue';
import Header from '@/views/_partials/header/Header.vue';
import HeaderTitle from '@/views/_partials/header/HeaderTitle.vue';
import NewComment from '@/components/Notifications/NewComment.vue';
import NewPost from '@/components/Notifications/NewPost.vue';
import NewUserConnectionRequest from '@/components/Notifications/NewUserConnectionRequest.vue';
import Pill from '@/components/Pills/Pill.vue';
import PillGroup from '@/components/Pills/PillGroup.vue';
import Skeleton from '@/components/Renderer/Skeleton.vue';

const store = useStore();
const views = {
    'new-comment': markRaw(NewComment),
    'new-post': markRaw(NewPost),
    'new-user-connection-request': markRaw(NewUserConnectionRequest),
};

const defaultView = markRaw(DefaultTemplate);

const state = reactive({
    activeFilter: null,
});

const notifications = useNotifications()

const filters = reactive({
    all: {
        label: 'All',
        notifications: notifications.collection,
        unread_count: computed(() => store.state.user?.unread_notifications_count ?? 0),
    },

    mention: {
        label: 'Mentions',
        notifications: collection({
            url: '/api/notifications',
            filter: {
                type: 'new-mention',
            }
        }),
        unread_count: computed(() => 0),
    },
});

const form = useAjaxForm();
const toast = useToast();
const notificationPlugin = useNotifications();

const markAllAsRead = () => {
    form.post('/api/notifications/mark-all-as-read', {
        onSuccess: () => {
            toast.publish({
                title: 'All notifications marked as read',
                color: 'success',
            });

            filters[state.activeFilter].notifications.data.forEach((data) => {
                data.read = true;
            });
            store.state.user.unread_notifications_count = 0;
        }
    });
};

const switchFilter = (filterKey) => {
    state.activeFilter = filterKey;
    filters[filterKey].notifications.fetch();
};

onBeforeMount(() => {
    state.activeFilter = 'all';
});

onMounted(() => {
    if (state.activeFilter) {
        filters[state.activeFilter].notifications.fetchOnce();
    }

    if (store.state.user.unread_notifications_count > 0) {
        notifications.clearUnreadNotificationsCount().then(() => {
            store.state.user.unread_notifications_count = 0;
        });
    }
});

</script>

<template>
    <Content>
        <Header>
            <HeaderTitle>
                Notifications
            </HeaderTitle>

            <div class="ml-auto">
            </div>


            <RouterLink :to="{ name: 'settings.notifications' }">
                <IconSettingsOutline class="size-6" />
                <span class="sr-only">Notification settings</span>
            </RouterLink>
        </Header>
        <div>
            <div class="px-4 pb-3 border-b border-b-white-foreground/10">
                <PillGroup class="mt-4" v-if="Object.keys(filters).length > 1">
                    <Pill as="button" type="button" @click="switchFilter(filterKey)" v-for="filterKey in Object.keys(filters)" :key="filterKey" :data-active="state.activeFilter === filterKey">
                        <div class="flex gap-1 items-center">
                            <span>{{ filters[filterKey].label }}</span>
                            <Badge v-if="filters[filterKey].unread_count > 0" :count="filters[filterKey].unread_count" />
                        </div>
                    </Pill>
                </PillGroup>
            </div>
            <template v-for="filterKey in Object.keys(filters)" :key="filterKey">
                <div v-if="state.activeFilter === filterKey">
                    <Skeleton :collection="filters[filterKey].notifications" />
                    <template v-if="!filters[filterKey].notifications.isEmpty">
                        <ul class="flex flex-col">
                            <component class="border-b border-b-white-foreground/10" :is="views[notification.type] ?? defaultView" v-for="notification in filters[filterKey].notifications.data" :key="notification.id" :notification="notification" />
                        </ul>
                        <InfiniteScrollObserver :collection="filters[filterKey].notifications" />
                    </template>

                    <div v-else class="flex flex-col items-center justify-center h-[calc(100vh-210px)] lg:h-[400px] p-4">
                        <IconBell class="opacity-80" height="80" width="80" />
                        <p class="font-bold text-xl text-center mt-4">No New Notifications</p>
                        <p class="text-base text-center">Notifications you received in the last 30 days will show up here.</p>
                    </div>
                </div>
            </template>
        </div>
    </Content>
</template>


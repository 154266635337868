import { computed, ref, reactive } from 'vue';
import { getToken } from 'firebase/messaging';

import { useFirebase } from '@/plugins/firebase';
import { useAjaxForm } from '@/utils/form';
import { useToast } from '@/plugins/toast';
import { mem_cache } from '@/utils/cache';

export const promptOpened = ref(false);

export const useNotificationPermission = () => {
    const supported = typeof Notification !== 'undefined';

    if (!supported) {
        return {
            supported: false,
            permission: 'default',
            prompt() {},
            promptOnce() {},
            refreshToken() {},
            enable() {},
            disable() {},
        };
    }

    const firebase = useFirebase();
    const toast = useToast();
    const tokenForm = useAjaxForm({
        token: '',
        alert: false,
    });

    const isStandalone = typeof window !== 'undefined' && window.matchMedia('(display-mode: standalone)').matches;
    const promptKey = 'notification.permission.' + (isStandalone ? 'standalone' : 'browser');

    const getTokenRefreshTtl = () => {
        const oneHour = 60 * 60;

        return oneHour;
    };

    const saveToken = async (alert = true) => {
        const registration = await navigator.serviceWorker.getRegistration('/sw.js');

        if (!registration) {
            throw new Error('No service worker registration found');
        }

        const messaging = firebase.getMessaging();

        const token = await getToken(messaging, { vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY, serviceWorkerRegistration: registration });

        tokenForm.token = token;
        tokenForm.alert = alert;

        tokenForm.post('/api/me/fcm-tokens', {});
    }

    return reactive({
        supported,
        permission: Notification.permission,
        prompt() {
            if (!this.supported) {
                return;
            }

            promptOpened.value = true;
        },
        promptOnce() {
            if (localStorage.getItem(promptKey) !== null) {
                return;
            }

            this.prompt();
        },
        async refreshToken() {
            if (Notification.permission !== 'granted') {
                return false;
            }

            const cacheStore = await mem_cache('device');
            const tokenRefreshed = await cacheStore.has('token_refreshed');

            if (tokenRefreshed) {
                return;
            }

            await saveToken(false);

            cacheStore.put('token_refreshed', true, getTokenRefreshTtl());

            return true;
        },
        async enable() {
            const permission = await Notification.requestPermission();

            this.permission = permission;

            if (permission !== 'granted') {
                return false;
            }

            await saveToken(true);

            const cacheStore = await mem_cache('device');
            cacheStore.put('token_refreshed', true, getTokenRefreshTtl());

            return true;
        },
        promptConfirmed() {
            promptOpened.value = false;
            localStorage.setItem(promptKey, 'confirmed');

            this.enable().then((granted) => {
                if (!granted) {
                    toast.info('You can reenable notifications in the settings.');
                }
            }).catch((err) => {
                toast.error('An error occurred while enabling notifications.');

                throw err;
            });
        },
        promptCancelled() {
            promptOpened.value = false;
            localStorage.setItem(promptKey, 'prompted');

            toast.info('You can reenable notifications in the settings.');
        },
    });
}

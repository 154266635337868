<script setup>
import { onMounted } from 'vue';
import { useRoute } from 'vue-router';

import { collection } from '@/composables/resource';

import UserList from '@/components/User/UserList.vue';
import Content from '@/views/_partials/Content.vue';
import Header from '@/views/_partials/header/Header.vue';
import HeaderTitle from '@/views/_partials/header/HeaderTitle.vue';

const props = defineProps({
    pageData: {
        type: Object,
        required: false,
    }
});

const route = useRoute();

const users = collection({
    url: '/api/search',
    filter: {
        query: route.query.query,
        type: 'user',
    }
});

onMounted(() => {
    if (!users.fetched) {
        users.fetch();
    }
});
</script>

<template>
    <div class="px-4">
        <UserList :collection="users" />
    </div>
</template>

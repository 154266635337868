<script setup>
import { ref } from 'vue';

import { useAjaxForm } from '@/utils/form';
import { useToast } from '@/plugins/toast';

import Content from '@/views/_partials/Content.vue';
import FormButton from '@/components/Form/FormButton.vue';
import FormInput from '@/components/Form/FormInput.vue';
import FormTwoFa from '@/components/Form/FormTwoFa.vue';
import Header from '@/views/_partials/header/Header.vue';
import HeaderTitle from '@/views/_partials/header/HeaderTitle.vue';

const toast = useToast();

const form = useAjaxForm({
    current_password: '',
    new_password: '',
    new_password_confirmation: '',
});

form.validate(() => {
    return form.current_password &&
        form.new_password
        && form.new_password_confirmation;
});

form.onSuccess(() => {
    toast.publish({
        title: 'Password updated successfully',
        color: 'success',
    });

    form.clear();
});

const submit = () => form.patch('/api/passwords');

const showTwoFa = ref(false);
</script>

<template>
    <Content class="bg-white text-white-foreground">
        <Header with-back-button>
            <HeaderTitle>
                Change Password
            </HeaderTitle>

            <div class="flex ml-auto">
                <FormButton :form="form" form-id="updatePassword" color="primary" compact lowercase>
                    change
                </FormButton>
            </div>
        </Header>

        <div class="p-4">
            <form @submit.prevent="submit" id="updatePassword">
                <div v-show="!showTwoFa">
                    <FormInput :form="form" name="current_password" type="password"/>
                    <FormInput :form="form" name="new_password" type="password"/>
                    <FormInput :form="form" name="new_password_confirmation" type="password" label="Re-Type New Password" />
                </div>

                <FormTwoFa :show="showTwoFa" :form="form" @update:show="(show) => showTwoFa = show" />
            </form>
        </div>
    </Content>
</template>

<script setup>
import axios from '@/axios';

import AppButton from '@/components/Button/Button.vue';
import Observer from '@/components/Observer.vue';
import UserAvatar from '@/components/User/UserAvatar.vue';
import { ref } from 'vue';

const props = defineProps({
    suggestion: {
        type: Object,
        required: true,
    },
    recordView: {
        type: Boolean,
        default: true,
    },
});

const recorded = ref(false);

const recordView = () => {
    if (recorded.value) {
        return;
    }

    axios.post(`/api/user-connect-suggestions/${props.suggestion.id}/view`);
    recorded.value = true;
};
</script>

<template>
    <div>
        <Observer @intersected="recordView" :threshold="0.1">
            <div class="shrink-0 h-[200px] w-[200px] border border-white-foreground/20 flex flex-col items-center px-2 py-2 rounded-md">
                <div class="w-full grow">
                    <UserAvatar size="3xl" :user="suggestion.suggested_user" />
                </div>

                <div class="font-semibold w-full truncate text-center">{{ suggestion.suggested_user.name }}</div>
                <div class="text-white-foreground/70 text-sm w-full truncate text-center">
                    {{ suggestion.suggested_user.profile.type_label }}

                    <span v-if="suggestion.suggested_user.profile.type === 1 || suggestion.suggested_user.profile.type === 3"
                        class="text-white-foreground/70 text-sm w-full truncate text-center">
                        - {{ $store.state.app.location_options[suggestion.suggested_user.profile.type_details.batch_state] }}
                    </span>
                </div>

                <AppButton type="button" color="primary" compact class="mt-2" @click.stop="$router.push(`/profiles/${suggestion.suggested_user.profile.username}/connect`)">
                    Connect
                </AppButton>
            </div>
        </Observer>
    </div>
</template>

import { reactive, inject } from "vue";

const settingsKey = Symbol('settings');

const createSettingsApp = () => {
    const darkModeCallbacks = [];

    const settings = reactive({
        darkMode: false,
        darkModePreference: 'device',
        setDarkModeOn () {
            this.setDarkMode('on');
        },
        setDarkModeOff () {
            this.setDarkMode('off');
        },
        setDarkModeDevice() {
            this.setDarkMode('device');
        },
        setDarkMode(preference) {
            localStorage.setItem('darkModePreference', preference);
            this.darkModePreference = preference;

            if (this.darkModePreference === 'on') {
                this.darkMode = true;
            } else if (this.darkModePreference === 'off') {
                this.darkMode = false;
            } else if (this.darkModePreference === 'device') {
                this.darkMode =  window.matchMedia('(prefers-color-scheme: dark)').matches;
            }

            darkModeCallbacks.forEach(darkModeCallback => darkModeCallback(this.darkMode));
        },
        onUpdateDarkMode(cb) {
            darkModeCallbacks.push(cb);
        }
    });

    if (typeof window !== 'undefined') {
        settings.setDarkMode(localStorage.getItem('darkModePreference') ?? 'device');

        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
            settings.setDarkMode(localStorage.getItem('darkModePreference') ?? 'device');
        });
    }

    return settings;
}

export function createSettings() {

    return {
        install(app) {
            const settings = createSettingsApp();
            app.config.globalProperties.$settings = settings;
            app.provide(settingsKey, settings);
        }
    };
}

export function useSettings() {
    const settings = inject(settingsKey);

    if (!settings) {
        throw new Error('useSettings() is called without provider.');
    }

    return settings;
};

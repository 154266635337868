<script setup>
import { computed, markRaw, onMounted, reactive, ref } from 'vue';
import { useRoute, RouterLink } from 'vue-router';

import { useAjaxForm } from '@/utils/form';
import { useToast } from '@/plugins/toast';
import { useResource } from '@/composables/resource';

import Alert from '@/components/Modals/Alert.vue';
import AppButton from '@/components/Button/Button.vue';
import AssignRole from '@/components/Member/AssignRole.vue';
import Avatar from '@/components/Avatar.vue';
import Checkbox from '@/components/Form/Checkbox.vue';
import DropdownMenu from '@/components/DropdownMenu/DropdownMenu.vue';
import DropdownMenuTrigger from '@/components/DropdownMenu/DropdownMenuTrigger.vue';
import IconAccount from '@/components/Icons/Account.vue';
import IconAccountCancel from '@/components/Icons/AccountCancel.vue';
import IconAccountConvert from '@/components/Icons/AccountConvert.vue';
import IconAccountSync from '@/components/Icons/AccountSync.vue';
import IconAdministrator from '@/components/Icons/Administrator.vue';
import IconAdminstratorOutline from '@/components/Icons/AdminstratorOutline.vue';
import IconAccountReactivateOutline from '@/components/Icons/AccountReactivateOutline.vue';
import IconBadminton from '@/components/Icons/Badminton.vue';
import IconDateRange from '@/components/Icons/DateRange.vue';
import IconMoreVert from '@/components/Icons/MoreVert.vue';
import Ban from '@/components/Member/Ban.vue';
import Modal from '@/components/Modals/Modal.vue';
import ModalTitle from '@/components/Modals/ModalTitle.vue';
import RoleBadge from '@/components/Member/RoleBadge.vue';

const emit = defineEmits(['member:updated']);
const props = defineProps({
    channel: Object,
});

const route = useRoute();
const toast = useToast();
const member = useResource(`/api/members/${route.query.member}`);

const activeAlert = ref(null);
const approveForm = useAjaxForm();
const declineForm = useAjaxForm({
    ban: false,
});
const banForm = useAjaxForm();
const assignRoleForm = useAjaxForm();

const approveMember = (alert) => {
    alert.setProcessing(true);
    approveForm.post(`/api/members/${member.data.id}/approve`, {
        onSuccess: (response) => {
            props.channel.fetch();
            member.fill(response.data);
            activeAlert.value = null;
            toast.success('Membership request approved');
        },
        onFinally: () => {
            alert.setProcessing(false);
        },
    });
}

const declineMember = (alert) => {
    alert.setProcessing(true);
    declineForm.post(`/api/members/${member.data.id}/decline`, {
        onSuccess: (response) => {
            props.channel.fetch();
            member.fill(response.data);
            activeAlert.value = null;
            toast.success(`Membership request declined${declineForm.ban ? ' and user banned from the channel' : ''}`);
        },
        onFinally: () => {
            alert.setProcessing(false);
        },
    });
}

const showAssignRoleAlert = ref(false);
const showBanAlert = ref(false);

const roleAssigned = (response) => {
    member.fill(response.data);
    showAssignRoleAlert.value = false;
    emit('member:updated');
}

const banned = (response) => {
    member.fill(response.data);
    showBanAlert.value = false;
    emit('member:updated');
}

const actions = [
    {
        icon: markRaw(IconAccountConvert),
        label: 'Change Role',
        onSelect() {
            showAssignRoleAlert.value = true;
        },
        as: 'button',
        show: () => props.channel?.data?.user_membership?.role_abilities['members:assign-role'],
    },
    {
        icon: markRaw(IconAccountCancel),
        label: 'Ban',
        onSelect() {
            showBanAlert.value = true;
        },
        as: 'button',
        show: () => props.channel?.data?.user_membership?.role_abilities['members:ban'] && member?.data?.status != 3,
    },
    {
        icon: markRaw(IconAccountSync),
        label: 'Lift ban',
        onSelect() {
            showBanAlert.value = true;
        },
        as: 'button',
        show: () => props.channel?.data?.user_membership?.role_abilities['members:ban'] && member?.data?.status == 3,
    },
];

onMounted(() => {
    member.fetch();
});
</script>

<template>
    <Modal :open="true" @update:open="$router.back()">
        <ModalTitle>
            Channel Member details
        </ModalTitle>
        <div v-if="!member.filled() && member.fetching" class="flex flex-col gap-2 items-center w-full animate-pulse">
            <div class="w-28 h-28 rounded-full bg-white-foreground/20"></div>
            <div class="flex flex-col gap-2 w-full">
                <div class="w-full h-[16px] rounded-full bg-white-foreground/20"></div>
                <div v-for="i in 4" :key="i" class="w-full h-[12px] rounded-full bg-white-foreground/20"></div>
            </div>
        </div>
        <template v-if="member.filled() && channel.data">
            <div class="flex flex-col items-center gap-4 mt-4">
                <div class="relative flex flex-col items-center">
                    <Avatar size="3xl" :src="member.data.user.profile.avatar?.url" :fallback-from="member.data.user.name" />

                    <div v-if="member.data.status != 1" class="mt-1 px-2 py-1 rounded-md text-sm font-medium w-fit absolute bottom-[-12px] border" :class="{
                        'bg-danger-light text-danger': member.data.status === 3,
                        'bg-warning-light text-warning': member.data.status === 0,
                    }">
                        {{ member.data.status === 3 ? 'Banned' : member.data.status === 0 ? 'Pending' : 'Active' }}
                    </div>
                </div>
                <RouterLink class="text-center" :to="{
                    name: 'profiles.show',
                    params: {
                        username: member.data.user.profile.username
                    }
                }">
                    <h2 class="text-xl leading-tight">{{ member.data.user.name }}</h2>
                    <p class="text-white-foreground/70 leading-tight">@{{ member.data.user.profile.username }}</p>
                </RouterLink>

                <div class="mt-3 flex gap-2">
                    <AppButton :as="RouterLink" compact capitalize color="primary" :to="{
                        name: 'profiles.show',
                        params: {
                            username: member.data.user.profile.username
                        }
                    }">
                        View profile
                    </AppButton>
                    <DropdownMenu v-if="member.data.status != 0 && channel.data.user_membership.role != 3" :items="actions" content-align="end">
                        <DropdownMenuTrigger>
                            <AppButton aria-label="actions" rounded-full compact capitalize color="primary-border">
                                <IconMoreVert class="size-6" />
                            </AppButton>
                        </DropdownMenuTrigger>
                    </DropdownMenu>
                </div>
            </div>

            <div class="mt-8">
                <div class="flex gap-4 items-center">
                    <div class="text-white-foreground/70">
                        <IconAccount class="size-6" />
                    </div>
                    <div class="leading-none">
                        <div class="font-medium">
                            {{ member.data.user.profile.type_label }}
                        </div>
                    </div>
                </div>

                <div class="flex gap-4 mt-4" v-if="member.data.status != 0">
                    <div class="text-white-foreground/70">
                        <IconDateRange class="size-6" />
                    </div>
                    <div class="leading-none">
                        <div class="font-medium">
                            Joined Channel
                        </div>
                        <div class="text-sm text-white-foreground/70">
                            {{ new Date(member.data.joined_at).toLocaleDateString() }}
                        </div>
                    </div>
                </div>

                <div class="flex gap-4 mt-4">
                    <div class="text-white-foreground/70">
                        <IconAdminstratorOutline class="size-6" />
                    </div>
                    <div class="leading-none">
                        <div class="font-medium">
                            Channel Role
                        </div>
                        <div class="mt-1 w-fit">
                            <RoleBadge :member="member.data" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-8 flex gap-4" v-if="member.data.status == 0">
                <div class="w-1/2">
                    <AppButton color="danger-border" capitalize compact full @click="activeAlert = 'decline'">
                        Decline
                        <Alert :open="activeAlert == 'decline'" @update:open="activeAlert = null" confirm-text="Yes, decline" title="Decline Member" @confirmed="declineMember" danger>
                            <div>
                                Are you sure you want to decline <span class="font-medium">{{ member.data.user.name }}</span> membership request?
                            </div>
                            <Checkbox v-model="declineForm.ban" id="ban">
                                <label class="ml-2" for="ban">Ban <span class="font-medium">{{ member.data.user.name }}</span> from the channel</label>
                            </Checkbox>
                        </Alert>
                    </AppButton>
                </div>
                <div class="w-1/2">
                    <AppButton color="primary-border" capitalize compact full @click="activeAlert = 'approve'">
                        Approve
                        <Alert :open="activeAlert == 'approve'" @update:open="activeAlert = null" confirm-text="Yes, approve" title="Approve Member" @confirmed="approveMember">
                            <div>
                                Are you sure you want to approve <span class="font-medium">{{ member.data.user.name }}</span> membership request?
                            </div>
                        </Alert>
                    </AppButton>
                </div>
            </div>
        </template>
        <Ban v-if="showBanAlert" :member="member.data" @success="banned" @cancelled="showBanAlert = false" />
        <AssignRole v-if="showAssignRoleAlert" :member="member.data" @success="roleAssigned" @cancelled="showAssignRoleAlert = false" />
    </Modal>
</template>

<script setup>
import { getCurrentInstance } from 'vue';
import { useRouter } from 'vue-router';

const props = defineProps({
    to: {
        required: true,
    },
});

const instance = getCurrentInstance();
const router = useRouter();

const push = () => {
    instance.emit('beforePush');

    router.push(props.to);
}
</script>

<template>
    <a :href="router.resolve(to).href" @click.prevent="push">
        <slot></slot>
    </a>
</template>

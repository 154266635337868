<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_944_3047)">
            <path d="M21.75 7.5C22.0547 7.5 22.3438 7.55859 22.6172 7.67578C22.8906 7.79297 23.1289 7.95312 23.332 8.15625C23.5352 8.35938 23.6992 8.59766 23.8242 8.87109C23.9492 9.14453 24.0078 9.4375 24 9.75C24 10 23.9609 10.2383 23.8828 10.4648L20.8828 19.4648C20.8047 19.6914 20.6953 19.8984 20.5547 20.0859C20.4141 20.2734 20.25 20.4375 20.0625 20.5781C19.875 20.7188 19.6719 20.8203 19.4531 20.8828C19.2344 20.9453 19 20.9844 18.75 21H12.75C11.3516 21 10.0039 20.7305 8.70703 20.1914C8.14453 19.9648 7.58203 19.793 7.01953 19.6758C6.45703 19.5586 5.86719 19.5 5.25 19.5H0V9H4.88672C5.39453 9 5.87109 8.90625 6.31641 8.71875C6.76172 8.53125 7.16797 8.25781 7.53516 7.89844L14.8242 0.621094C15.0195 0.425781 15.2148 0.273438 15.4102 0.164062C15.6055 0.0546875 15.8438 0 16.125 0C16.3828 0 16.625 0.046875 16.8516 0.140625C17.0781 0.234375 17.2773 0.371094 17.4492 0.550781C17.6211 0.730469 17.7539 0.929688 17.8477 1.14844C17.9414 1.36719 17.9922 1.60938 18 1.875C18 2.50781 17.9297 3.10938 17.7891 3.67969C17.6484 4.25 17.4414 4.82031 17.168 5.39062C17.0117 5.72656 16.8789 6.06641 16.7695 6.41016C16.6602 6.75391 16.5859 7.11719 16.5469 7.5H21.75Z" fill="currentColor"/>
        </g>
        <defs>
            <clipPath id="clip0_944_3047">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script setup>
import { useAjaxForm } from '@/utils/form';
import { useStore } from '@/store';
import { useToast } from '@/plugins/toast';

import Content from '@/views/_partials/Content.vue';
import FormInput from '@/components/Form/FormInput.vue';
import FormButton from '@/components/Form/FormButton.vue';
import Header from '@/views/_partials/header/Header.vue';
import HeaderTitle from '@/views/_partials/header/HeaderTitle.vue';

const store = useStore();
const toast = useToast();

const user = store.state.user;
const form = useAjaxForm({
    name: user.name,
    username: user.profile.username,
    gender: user.profile.gender,
    type: user.profile.type,
    type_details: {
        batch: user.profile.type_details?.batch ?? null,
        batch_stream: user.profile.type_details?.batch_stream ?? null,
        batch_year: user.profile.type_details?.batch_year ?? null,
        batch_state: user.profile.type_details?.batch_state ?? null,
    },
    avatar: user.profile.avatar,
    bio: user.profile.bio,
    school: user.profile.school,
});

form.onSuccess((response) => {
    store.commit('user', response.data.data);
    localStorage.setItem('me', JSON.stringify(response.data.data));
    toast.publish({
        title: 'Profile updated successfully',
        color: 'success',
    });
});

const updateProfile = () => {
    form.patch(`/api/profiles/${user.profile.id}`);
};
</script>

<template>
    <Content class="bg-white text-white-foreground">
        <Header with-back-button>
            <HeaderTitle>
                Edit Profile
            </HeaderTitle>

            <div class="flex ml-auto">
                <FormButton :form="form" form-id="updateProfile" color="primary" compact capitalize>
                    Save
                </FormButton>
            </div>
        </Header>

        <div class="px-4 py-4">
            <form @submit.prevent="updateProfile" id="updateProfile">
                <FormInput :form="form" as="file" name="avatar" :input-bind="{
                    accept: 'image/png,image/jpeg,image/jpg,image/webp,image/heic',
                    roundedFull: true,
                    ariaLabel: 'Avatar',
                    size: '3xl',
                }" />
                <FormInput label="Name" name="name" :form="form" />
                <FormInput lowercase label="Username" name="username" :disabled="!store.state.user.profile.can_update_username"
                    :form="form"
                    :help="`Username can be updated in ${store.state.app.username_change_countdown} days`" />
                <FormInput :form="form" as="select" name="gender" label="Gender" placeholder="Select your gender"
                    required :input-bind="{
                    options: store.state.app.gender_options
                }" />
                <FormInput :form="form" as="select" name="school" label="School" placeholder="Select your school"
                    required :input-bind="{
                    options: store.state.app.school_options
                }" />
                <FormInput :form="form" as="textarea" name="bio" label="Bio" placeholder="Tell us about yourself" />
                <FormInput :form="form" as="select" name="type" label="Which profile type best suits you?"
                    placeholder="Select One" required :input-bind="{
                    options: store.state.app.profile_type_options
                }" />
                <FormInput v-if="form.type == 1 || form.type == 3" :form="form" as="input"
                    name="type_details.batch_year" label="Year of Deployment" placeholder="2xxx"
                    v-model="form.type_details.batch_year" :input-bind="{
                    min: 1960,
                    max: 2024,
                    type: 'number'
                }" />
                <FormInput v-if="form.type == 1 || form.type == 3" :form="form" v-model="form.type_details.batch"
                    as="select" name="type_details.batch" label="Batch" placeholder="Select Batch" required :input-bind="{
                    options: {
                        'A': 'A',
                        'B': 'B',
                        'C': 'C',
                    }
                }" />
                <FormInput v-if="form.type == 1 || form.type == 3" :form="form" v-model="form.type_details.batch_stream"
                    as="select" name="type_details.batch_stream" label="Stream" placeholder="Select Stream" required
                    :input-bind="{
                    options: {
                        'I': 'I',
                        'II': 'II',
                    }
                }" />
                <FormInput v-if="form.type == 1 || form.type == 3" :form="form" v-model="form.type_details.batch_state"
                    as="select" name="type_details.batch_state" label="State of Deployment"
                    placeholder="Select State Of Deployment" required :input-bind="{
                    options: store.state.app.location_options
                }" />
            </form>
        </div>
    </Content>
</template>

<script setup>
import Error from '@/components/Form/Error.vue';
import File from '@/components/Form/File.vue';
import Label from '@/components/Form/Label.vue';
import Input from '@/components/Form/Input.vue';
import Select from '@/components/Form/Select.vue';
import Textarea from '@/components/Form/Textarea.vue';
import { computed, reactive, ref } from 'vue';

const value = defineModel();

const props = defineProps({
    form: {
        required: true,
        type: Object,
    },
    name: {
        required: true,
        type: String,
    },
    label: {
        required: false,
        type: String,
        default: '',
    },
    placeholder: {
        required: false,
        type: String,
        default: '',
    },
    as: {
        required: false,
        type: String,
        default: 'input',
    },
    help: {
        required: false,
        type: String,
        default: '',
    },
    inputBind: {
        required: false,
        type: Object,
        default: {},
    },
    required: {
        required: false,
        type: Boolean,
        default: false,
    },
    lowercase: {
        required: false,
        type: Boolean,
        default: false,
    },
    type: {
        required: false,
    },
    disabled: {
        required: false,
        type: Boolean,
        default: false,
    },
    autocomplete: {
        required: false,
    }
});

const passwordType = ref('password');

const componentMap = {
    input: Input,
    select: Select,
    textarea: Textarea,
    file: File,
}
const bind = reactive({
    ...props.inputBind,
    id: props.name,
    name: props.name,
    required: props.required,
    placeholder: props.placeholder,
    type: props.type === 'password' ? passwordType : props.type,
    lowercase: props.lowercase,
    debounce: props.debounce,
    autocomplete: props.autocomplete,
})

const getLabel = computed(() => {
    if (props.label) {
        return props.label;
    }

    return props.name.split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
});
</script>

<template>
    <div class="mt-6 first:mt-0">
        <Label v-if="getLabel" :for="name">{{ getLabel }}</Label>
        <div class="relative">
            <component v-if="(typeof value === 'undefined')" :is="componentMap[as]" v-model="form[name]" v-bind="bind" :disabled="disabled" />
            <component v-else :is="componentMap[as]" v-model="value" v-bind="bind" :disabled="disabled" />
            <button v-if="as === 'input' && props.type === 'password'" type="button"
                class="absolute top-0 right-0 h-full text-sm underline font-medium px-2"
                @click="passwordType === 'text' ? passwordType = 'password' : passwordType = 'text'">
                {{ passwordType === 'text' ? 'Hide' : 'Show' }}
            </button>
        </div>
        <small class="opacity-70 text-xs block mt-1" v-if="help">{{ help }}</small>
        <Error :value="form.errors[name]" />
    </div>
</template>

import mitt from 'mitt';
import { inject } from "vue";

const key = Symbol('emitter');

export function useEmitter() {
    const emitter = inject(key);

    if (!emitter) {
        throw new Error('useEmitter() is called without provider.');
    }

    return emitter;
}

export function createEmitter() {
    return {
        install(app) {
            const emitter = mitt();
            app.config.globalProperties.$emitter = emitter;
            app.provide(key, emitter);
        }
    }
}

<script setup>
import { computed, ref } from "vue";

import { useStore } from "@/store";

import IconLoader from '@/components/Icons/Loader.vue';

const sw_caching = import.meta.env.VITE_SW_CACHING;
const store = useStore();

const show = computed(() => {
    return sw_caching !== 'off' && store.state.updating;
});

</script>

<template>
    <div v-if="show" class="bg-primary text-primary-foreground">
        <div class="w-full flex items-center justify-between gap-2 p-3">
            <div class="font-medium">New update downloaded! Refreshing now!!</div>
            <div class="shrink-0">
                <button type="button" class="font-medium flex items-center justify-center gap-1 select-none border border-primary-foreground px-2 py-0.5 rounded-full disabled:opacity-60" @click="update" :disabled="updating">
                    <IconLoader v-if="store.state.updating" class="size-6" />
                </button>
            </div>
        </div>
    </div>
</template>

<template>
    <div class="flex min-w-0 max-w-[350px] flex-col items-center justify-center">

        <div class="relative w-[200px] max-h-[450px] translate-x-8">
            <img class=" z-10 h-auto w-auto relative object-contain" src="@images/screenshot-feed.png" />
            <div class="w-full h-full absolute bottom-0 z-0 rotate -translate-x-14 -translate-y-2 -rotate-[20deg]">
                <img class="h-full w-auto object-contain" src="@images/screenshot-question.png" />
            </div>
        </div>

        <div class="text-center mt-6 text-base leading-snug">
            Connect with prospective, former, or fellow NYSC members. Participate in discussions and explore interest groups
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { collectionV2 } from '@/composables/resource';
import { pluralize } from '@/utils';
import { useStore } from '@/store';
import { useToast } from '@/plugins/toast';
import axios from '@/axios';

import Content from '@/views/_partials/Content.vue';
import Header from '@/views/_partials/header/Header.vue';
import HeaderTitle from '@/views/_partials/header/HeaderTitle.vue';
import IconContentCopy from '@/components/Icons/ContentCopy.vue';
import IconSearch from '@/components/Icons/Search.vue';
import UserList from '@/components/User/UserList.vue';

const route = useRoute();
const router = useRouter();
const store = useStore();
const toast = useToast();
const header = ref();
const stats = ref(null);

const referrals = collectionV2({
    url: '/api/referrals',
});

const appUrl = import.meta.env.VITE_APP_URL;
const referralLink = `${appUrl}/amb/${store.state.user.referral_code}`;

const copyReferralLink = () => {
    navigator.clipboard.writeText(referralLink)
        .then(() => {
            toast.info('Referral link copied');
        })
        .catch((error) => {
            toast.error('Failed to copy referral link to clipboard');
            console.error(error);
        });
};

onMounted(() => {
    referrals.fetch();
    axios.get('/api/referral-stats').then((res) => {
        stats.value = res.data;
    });
});
</script>

<template>
    <Content>
        <Header ref="header" with-back-button :search-collection="referrals" search-placeholder="Search your referrals">
            <HeaderTitle>
                Your Referrals
            </HeaderTitle>

            <div class="grow"></div>
            <button @click="header.openSearch" type="button" aria-label="Open search">
                <IconSearch class="size-7" />
            </button>
        </Header>

        <div class="mt-3 px-4">
            <div class="text-sm">
                Referral Link:
                <button type="button" aria-label="Copy referral code" @click="copyReferralLink">
                    <span class="font-semibold">{{ referralLink }}</span><IconContentCopy class="inline ml-1 size-4" />
                </button>
            </div>
            <div v-if="stats" class="text-sm">
                Total Active: {{ stats.total_active }}
            </div>
            <div v-if="stats" class="text-sm">
                Total Pending: {{ stats.total_pending }}
            </div>
            <UserList class="mt-3" :collection="referrals" />
        </div>
    </Content>
</template>

import { inject } from 'vue';
import { initializeApp } from "firebase/app";
import { getMessaging } from 'firebase/messaging';

const key = Symbol('firebase');

const createPlugin = () => {
    const app = initializeApp(JSON.parse(import.meta.env.VITE_FIREBASE_CONFIG));
    const messaging = getMessaging(app);

    return {
        app() {
            return app;
        },
        getMessaging() {
            return messaging;
        }
    }
}

export function useFirebase() {
    const firebase = inject(key);

    if (!firebase) {
        throw new Error('useFirebase() is called without provider.');
    }

    return firebase;
}

export default {
    install (app) {
        const plugin = createPlugin();
        app.provide(key, plugin);
    }
}

import { openDB } from "idb";

const getDefaultValue = async (value) => {
    if (value && typeof value === 'function') {
        return await value();
    }

    return value;
}

const getExpiry = (ttl) => {
    if (ttl == 0) {
        return Date.now() + ttl * 1000;
    }

    if (ttl) {
        return Date.now() + ttl * 1000;
    }

    return ttl;
}

export const db_store = async (storeName) => {
    const db = await openDB('cache-db', 1, {
        upgrade(db) {
            if (!db.objectStoreNames.contains(storeName)) {
                db.createObjectStore(storeName);
            }
        },
    });

    return {
        async get(key) {
            return db.transaction(storeName).objectStore(storeName).get(key);
        },
        async add(value, key) {
            return db.transaction(storeName, 'readwrite').objectStore(storeName).add(value, key);
        },
        async put(value, key) {
            return db.transaction(storeName, 'readwrite').objectStore(storeName).put(value, key);
        },
        async delete(key) {
            return db.transaction(storeName, 'readwrite').objectStore(storeName).delete(key);
        },
        async clear() {
            return db.transaction(storeName, 'readwrite').objectStore(storeName).clear();
        }
    };
}

const mem_stores = new Map();

export const mem_store = (storeName, maxSize = 100) => {
    if (!mem_stores.has(storeName)) {
        mem_stores.set(storeName, {
            map: new Map(),
            order: new Set(),
            listeners: new Map(),
        });
    }

    const store = mem_stores.get(storeName);

    const updateOrder = (key) => {
        store.order.delete(key);
        store.order.add(key);
    }

    const evictLRU = () => {
        const firstKey = store.order.values().next().value;
        store.order.delete(firstKey);
        store.map.delete(firstKey);

        const onEvicted = store.listeners.get(`evicted:${firstKey}`);

        if (onEvicted) {
            onEvicted();
        }
    }

    return {
        get(key) {
            if (store.map.has(key)) {
                updateOrder(key);
            }

            return store.map.get(key);
        },
        has(key) {
            return store.map.has(key);
        },
        add(value, key) {
            if (this.has(key)) {
                return false;
            }

            if (store.map.size >= maxSize) {
                evictLRU();
            }

            store.map.set(key, value);
            updateOrder(key);

            return true;
        },
        put(value, key) {
            if (store.map.size >= maxSize && !this.has(key)) {
                evictLRU();
            }

            store.map.set(key, value);
            updateOrder(key);
        },
        delete(key) {
            store.order.delete(key);
            store.map.delete(key);
        },
        clear() {
            store.order.clear();
            store.map.clear();
        },
        remember(key, defaultValue) {
            if (this.has(key)) {
                return this.get(key);
            }

            const getDefaultValue = () => {
                if (defaultValue && typeof defaultValue === 'function') {
                    return defaultValue({
                        onEvicted: function (cb) {
                            return function () {
                                store.listeners.set(`evicted:${key}`, cb);
                            };
                        }
                    });
                }

                return defaultValue;
            }

            this.add(getDefaultValue(defaultValue), key);

            return this.get(key);
        }
    };
}

export const cache = async (driver, storeName) => {
    const drivers = {
        database: async () => await db_store(storeName),
        memory: () => mem_store(storeName),
    };

    if (typeof window === 'undefined') {
        driver = 'memory';
    }

    if (!drivers[driver]) {
        throw new Error(`Invalid cache driver: ${driver}`);
    }

    const store = await drivers[driver]();

    const getEntry = async (key) => {
        const entry = await store.get(key);

        if (entry &&entry.expiry && Date.now() > entry.expiry) {
            await store.delete(key);

            return null;
        }

        return entry;
    }

    return {
        async get (key, defaultValue = null) {
            const entry = await getEntry(key);

            if (!entry) {
                return getDefaultValue(defaultValue);
            }

            entry.hits++;

            await store.put(entry, key);

            return entry.value;
        },
        async add (key, value, ttl) {
            const entry = await getEntry(key);

            if (entry) {
                return false;
            }

            await store.add({
                hits: 0,
                value,
                expiry: getExpiry(ttl)
            }, key);

            return true;
        },
        async put (key, value, ttl) {
            const entry = { hits: 0, value, expiry: getExpiry(ttl) };

            await store.put(entry, key);
        },
        async forget (key) {
            await store.delete(key);
        },
        async flush () {
            await store.clear();
        },
        async pull (key) {
            const value = await this.get(key);

            await this.forget(key);

            return value;
        },
        async remember (key, ttl, defaultValue) {
            const entry = await getEntry(key);

            if (entry) {
                entry.hits++;

                await store.put(entry, key);

                return entry.value;
            }

            const value = await getDefaultValue(defaultValue);

            await this.put(key, value, ttl);

            return value;
        },
        async rememberForever (key, value) {
            return this.remember(key, null, value);
        },
        async forever (key, value) {
            return this.put(key, value, null);
        },
        async has (key) {
            const entry = await this.get(key);

            return entry ? true : false;
        },
        async hits (key) {
            const entry = await getEntry(key);

            return entry ? entry.hits : 0;
        }
    };
}

export const db_cache = async (storeName) => {
    return cache('database', storeName);
}

export const mem_cache = async (storeName) => {
    return cache('memory', storeName);
}

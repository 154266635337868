<script setup>

const checked = defineModel();

defineProps({
    id: {
        type: String,
        required: false,
    },
    name: {
        type: String,
        required: false,
    },
});
</script>

<template>
    <div class="mt-5">
        <input type="checkbox" :id="id" :name="name" v-model="checked"
            class="text-primary shadow-primary focus:border-primary focus:outline-primary rounded-sm" />
            <slot></slot>
    </div>
</template>

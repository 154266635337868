import { onMounted, reactive } from "vue";

const fetchUrls = {
    channel: (key) => `/api/channels/${key}`,
};

const state = reactive({});

export const setPageData = (page, key, data) => {
    state[page] = {
        [key]: data,
    }
}

export function usePage(page, key, defaultData) {
    if (!state[page] || !state[page][key]) {
        setPageData(page, key, defaultData ?? {});
    }

    onMounted(() => {
        // @TODO fetch data if no data given.
    });

    return state[page][key];
}

<script setup>
import { getCurrentInstance } from 'vue';
import { useRouter } from 'vue-router';

import AppButton from '@/components/Button/Button.vue';
import Modal from '@/components/Modals/Modal.vue';
import ModalTitle from '@/components/Modals/ModalTitle.vue';
import { useAjaxForm } from '@/utils/form';

defineEmits(['messages:deleted']);

const instance = getCurrentInstance();

const props = defineProps({
    messages: Array,
    conversationMemberId: String,
});

const form = useAjaxForm({
    messages: props.messages.map((message) => message.id),
});

form.onSuccess((response) => {
    instance.emit('messages:deleted', { messages: props.messages.map((message) => message.id), response: response });
    router.back();
});

const router = useRouter();
</script>

<template>
    <div>
        <Modal :open="true" @update:open="router.back()">
            <ModalTitle show>
                <h1 class="text-xl font-semibold">Delete {{ messages.length }} messages</h1>
            </ModalTitle>

            <div class="mt-2">
                Deleting these messages will remove them from your conversation.
            </div>

            <div class="flex gap-2 mt-4">
                <div class="w-1/2">
                    <AppButton color="gray-border" @button:click="router.back()" full>
                        Cancel
                    </AppButton>
                </div>
                <div class="w-1/2">
                    <AppButton color="danger" full :loading="form.processing" @button:click="form.post(`/api/conversations/${conversationMemberId}/delete-messages`)">
                        Delete for me
                    </AppButton>
                </div>
            </div>
        </Modal>
    </div>
</template>

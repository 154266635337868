<script setup>
import { inject, nextTick, onMounted, onUnmounted, ref } from 'vue';

import { collectionV2 } from '@/composables/resource';
import { useReaction } from '@/utils/reaction';
import { useEmitter } from '@/plugins/emitter';
import { useStore } from '@/store';

import Actions from '@/components/Comment/Actions.vue';
import Avatar from '@/components/Avatar.vue';
import ContentImages from '@/components/ContentImages.vue';
import Content from '@/components/Renderer/Content.vue';
import IconThumbsDown from '@/components/Icons/ThumbsDown.vue';
import IconThumbsDownFilled from '@/components/Icons/ThumbsDownFilled.vue';
import IconThumbsUp from '@/components/Icons/ThumbsUp.vue';
import IconThumbsUpFilled from '@/components/Icons/ThumbsUpFilled.vue';
import IconLoader from '@/components/Icons/Loader.vue';
import LineClamp from '@/components/LineClamp.vue';
import ProfileLink from '@/components/Link/ProfileLink.vue';
import Skeleton from '@/components/Renderer/Skeleton.vue';

const onReplyButtonClicked = inject('onReplyButtonClicked');

const props = defineProps({
    comment: {
        required: true,
        type: Object,
    },
    openFile: {
        type: Boolean,
        default: false,
    },
    isMember: {
        type: Boolean,
        required: false,
    },
});

const store = useStore();
const emit = defineEmits(['deleted', 'reply:button-clicked']);
const reaction = useReaction(props.comment.id, props.comment.is_reply ? 'reply' : 'comment', {
    total_likes: props.comment.positive_reactions_count,
    user_reaction: props.comment.user_reaction ? props.comment.user_reaction.type : null,
});

const replies = collectionV2({
    uniqueById: true,
    url: `/api/comments/${props.comment.id}/replies`,
    params: {
        per_page: 10,
    },
});

const showReplies = ref(false);
const forceShowReplies = ref(false);
const el = ref();

const fetchingReplies = ref(false);

const loadMore = () => {
    if (!replies.links.next) {
        return;
    }

    fetchingReplies.value = true;
    replies.nextCursor().finally(() => {
        fetchingReplies.value = false;
    });
}

const replyCreated = (reply) => {
    if (!forceShowReplies.value) {
        forceShowReplies.value = true;
    }

    replies.push(reply);
    replies.setFetched(true);
    props.comment.replies_count++;

    if (props.comment.replies_count < 1000) {
        props.comment.replies_count_abbrv = props.comment.replies_count;
    }

    nextTick(() => {
        el.value.scrollIntoView({
            behavior: 'smooth',
            block: 'end'
        });
    });
}

const toggleShowReplies = () => {
    showReplies.value = !showReplies.value;

    if (showReplies.value && (!replies.fetched || forceShowReplies.value)) {
        replies.fetch().then(() => {
            if (forceShowReplies.value) {
                forceShowReplies.value = false;
            }
        });
    }
}

const replyDeleted = (reply) => {
    replies.delete(reply.id);
    props.comment.replies_count--;
    if (props.comment.replies_count < 1000) {
        props.comment.replies_count_abbrv = props.comment.replies_count;
    }
};

const handleReplyClicked = (e) => {
    const data = {
        for: props.comment.id,
        commentId: props.comment.is_reply ? props.comment.parent_id : props.comment.id,
        text: props.comment.is_reply && store.state.user.profile.username !== props.comment.user.profile.username ? '@' + props.comment.user.profile.username + ' ' : null,
        target: e.target,
        username: props.comment.user.profile.username,
    };

    if (onReplyButtonClicked) {
        onReplyButtonClicked(data);
    } else {
        emit('reply:button-clicked', data);
    }
}

const onReplyCreated = (e) => {
    replyCreated(e.data);
};

const emitter = useEmitter();

onMounted(() => {
    if (!props.comment.is_reply) {
        emitter.on('reply:created.' + props.comment.id, onReplyCreated);
    }
});

onUnmounted(() => {
    if (!props.comment.is_reply) {
        emitter.off('reply:created.' + props.comment.id, onReplyCreated);
    }
});
</script>

<template>
    <div ref="el" class="flex gap-2">
        <ProfileLink class="block shrink-0" :profile="comment.user.profile">
            <Avatar size="sm" :fallback-from="comment.user.name" :src="comment.user.profile.avatar?.url" />
        </ProfileLink>
        <div class="grow min-w-0">
            <div class="flex w-full gap-2">
                <div class="leading-none grow min-w-0">
                    <ProfileLink class="block truncate" :profile="comment.user.profile">
                        <span class="font-semibold">{{ comment.user.name }}</span>
                        <span class="ml-1 text-white-foreground/60">@{{ comment.user.profile.username }}</span>
                    </ProfileLink>
                    <div class="text-sm opacity-60 shrink-0">
                        {{ comment.published_at_diff }} ago
                    </div>
                </div>
                <Actions :comment="comment" @deleted="$emit('deleted', comment)" />
            </div>

            <div>
                <LineClamp>
                    <Content :content="comment.content" :mentions="comment.mentions.map(m => m.username)" />
                    <ContentImages v-if="comment.files" class="mt-2" :images="comment.files" open-file />
                </LineClamp>

                <div class="mt-1 -ml-2 text-white-foreground/60 flex justify-start text-xs">
                    <button type="button" class="flex py-2 px-2 justify-center items-center gap-2"
                        @click="reaction.like">
                        <component :is="reaction.liked ? IconThumbsUpFilled: IconThumbsUp"
                            :class="{ 'text-[#F24E1E]': reaction.liked }" width="16" height="16" />
                        <span aria-hidden="true">{{ reaction.total_likes }}</span>
                    </button>
                    <button type="button" class="flex py-2 px-2 justify-center items-center gap-2"
                        @click="reaction.dislike">
                        <component :is="reaction.disliked ? IconThumbsDownFilled: IconThumbsDown"
                            :class="{ 'text-[#F24E1E]': reaction.disliked }" width="16" height="16" />
                    </button>
                    <button v-if="isMember" @click="handleReplyClicked" type="button" class="ml-2 px-2 font-medium -mt-0.5">Reply</button>
                </div>
            </div>

            <div v-if="!comment.is_reply" class="mt-2">
                <div v-if="showReplies || forceShowReplies" class="mt-4">
                    <Skeleton :count="1" ignore-height :collection="replies" />
                    <ul class="flex flex-col gap-2" v-if="replies.data">
                        <li v-for="reply in replies.data" :key="reply.id" class="transition-colors" :class="{
                            'animate-[splash_1s_ease-in-out_1] fill-mode-none': reply._clientMeta?.isNew,
                        }">
                            <CommentContent :comment="reply" @deleted="replyDeleted" :isMember="props.isMember" />
                        </li>
                    </ul>
                    <div
                        v-if="fetchingReplies && replies.fetched && replies.links.next"
                        class="w-full py-2 flex items-center justify-center">
                        <span class="sr-only">Loading more</span>
                        <IconLoader class="size-6 opacity-70" />
                    </div>
                    <div class="flex justify-center text-sm italic font-medium text-white-foreground/60 mt-2"
                        v-if="replies.fetched && replies.data.length > 0">
                        <button v-if="replies.links.next" type="button" @click="loadMore">Load more</button>
                    </div>
                </div>
                <button v-if="comment.replies_count > 0" @click="toggleShowReplies"
                    class="text-xs text-white-foreground/60 font-medium" type="button">{{ showReplies ? 'Hide' : `Show
                    ${comment.replies_count_abbrv}` }} {{ comment.replies_count > 1 ? 'replies' : 'reply' }}</button>
                <span v-else class="text-xs text-white-foreground/60 font-medium">No replies</span>
            </div>
        </div>
    </div>
</template>

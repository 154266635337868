<script setup>
import { onMounted, ref, nextTick, getCurrentInstance } from 'vue';

import { useAjaxForm } from '@/utils/form';
import { useLoginHandler } from '@/composables/actions';
import { useRouter } from 'vue-router';
import { useStore } from '@/store';
import { useToast } from '@/plugins/toast';

import AppButton from '@/components/Button/Button.vue';
import FormInput from '@/components/Form/FormInput.vue';

defineEmits(['loading']);

const instance = getCurrentInstance();
const store = useStore();
const toast = useToast();
const router = useRouter();
const loginHandler = useLoginHandler();

const clientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;
const googleLoginDiv = ref();

const loginForm = useAjaxForm({
    credential: null,
});

const form = useAjaxForm({
    credential: null,
});

form.retrievesCsrf();

form.onSuccess((response) => {
    const query = {
        credential: form.credential,
        email: response.data.payload.email,
    }

    if (!response.data.exists) {
        router.push({
            name: 'social.register',
            query
        })
    } else if (!response.data.linked) {
        router.push({
            name: 'social.login',
            query
        })
    } else {
        loginForm.credential = form.credential;
        loginForm.post('/auth/social');
    }
});

loginForm.onSuccess((response) => {
    loginHandler.handle(response);
    router.push({ name: 'feed' });
});

loginForm.onFinally(() => instance.emit('loading', false));

const handleCredentialResponse = (response) => {
    instance.emit('loading', true);
    form.credential = response.credential;
    form.post('/auth/social/verify');
}

onMounted( async() => {
    await nextTick();
    google.accounts.id.initialize({
        client_id: clientId,
        callback: handleCredentialResponse,
    });

    google.accounts.id.renderButton(googleLoginDiv.value, {
        type: 'standard',
        shape: 'pill',
        theme: 'outline',
        size: 'large',
        text: 'continue_with',
        width: `${googleLoginDiv.value.offsetWidth}px`,
        logo_alignment: 'center',
    });
})
</script>

<template>
    <div class="flex gap-2 flex-col items-center">
        <div class="w-full h-[44px] transition-all" ref="googleLoginDiv"></div>
    </div>
</template>

<script setup>
import { onMounted } from "vue";
import { useRouter } from "vue-router";

import { useAjaxForm } from "@/utils/form";
import { collectionV2 } from "@/composables/resource";

import ConversationLayout from '@/views/_partials/ConversationLayout.vue';
import ConversationContent from '@/views/_partials/ConversationContent.vue';
import ConversationSidebarContent from '@/views/conversations/_helpers/ConversationSidebarContent.vue';
import Header from '@/views/_partials/header/Header.vue';
import Input from "@/components/Form/Input.vue";
import HeaderTitle from '@/views/_partials/header/HeaderTitle.vue';
import UserCard from "@/components/User/UserCard.vue";
import UserList from "@/components/User/UserList.vue";

const router = useRouter();
const users = collectionV2({
    url: '/api/users',
});

const props =  defineProps({
    baseProps: Object,
});

const runSearch = (value) => {
    users.setParams({
        query: value || undefined,
    });
    users.fetch();
}

const startChatForm = useAjaxForm({
    user: '',
});

const startChat = (userId) => {
    startChatForm.user = userId;

    startChatForm.post('/api/conversations', {
        onSuccess: (response) => {
            const newConversation = response.data.data;

            if (props.baseProps.acceptedConversations.fetched && !props.baseProps.acceptedConversations.data.find((c) => c.id == newConversation.id)) {
                props.baseProps.acceptedConversations.push(newConversation);
            }

            router.replace({
                name: 'conversations.show',
                params: {
                    id: newConversation.id,
                },
            });
        },
    });
}

onMounted(() => {
    users.fetch();
});
</script>

<template>
    <ConversationLayout>
        <ConversationSidebarContent :baseProps="baseProps" />

        <ConversationContent>
            <Header back-button-only-mobile>
                <HeaderTitle>
                    Start Chat
                </HeaderTitle>
            </Header>

            <div class="px-4">
                <div class="mt-3">
                    <Input
                        autofocus="true"
                        type="search"
                        :debounce="200"
                        placeholder="Search for a user"
                        @finished-typing="runSearch" />
                </div>
                <div class="mt-3">
                    <UserList :collection="users">
                        <template #default="{ user }">
                            <button type="button" class="block w-full text-left" @click="startChat(user.id)">
                                <UserCard :user="user" />
                            </button>
                        </template>
                    </UserList>
                </div>
            </div>
        </ConversationContent>
    </ConversationLayout>
</template>

<script setup>
import { onMounted } from "vue";

import { useRoute, useRouter } from "vue-router";
import { useAjaxForm } from "@/utils/form";
import { useLoginHandler } from "@/composables/actions";
import { getPlatoon } from "@/utils";
import { useToast } from "@/plugins/toast";

import AppButton from '@/components/Button/Button.vue';
import Checkbox from '@/components/Form/Checkbox.vue';
import PrivacyLink from "@/components/PrivacyLink.vue";

const toast = useToast();
const router = useRouter();
const route = useRoute();
const loginHandler = useLoginHandler();
const form = useAjaxForm({
    email: route.query.email,
    credential: route.query.credential,
    privacy_policy: false,
    platoon: null,
    referral_code: null,
});

form.retrievesCsrf();

form.onSuccess((response) => {
    loginHandler.handle(response);
    router.push({ name: 'feed' });
});

form.onError(() => {
    if (form.errors.referral_code) {
        toast.error('Invalid referral code. To signup without a referral code, remove the referral code from the registration form and try again.');
    }
});

onMounted(() => {
    form.platoon = getPlatoon();
    form.referral_code = localStorage.getItem('referral_code');
});

</script>

<template>
    <div class="w-full mt-6">
        <p class="text-center text-lg">
            An account will be created for you with the email <span class="font-semibold text-primary">{{ route.query.email }}</span>, Please click the button below to continue.
        </p>

        <Checkbox :id="'privacy_policy'" v-model="form.privacy_policy" label="I agree to corperland's privacy policy">
            <PrivacyLink :id="'privacy_policy'" />
        </Checkbox>

        <div v-if="form.errors?.privacy_policy" class="mt-1">
            <Error :value="form.errors.privacy_policy" />
        </div>

        <div class="mt-10">
            <AppButton @click="form.post('/auth/social')" full color="primary" roundedFull
                :disabled="!form.privacy_policy" :loading="form.processing">Confirm Sign Up</AppButton>
        </div>
    </div>
</template>

<script setup>
import { RouterLink } from 'vue-router';

import { useAjaxForm } from '@/utils/form';
import { useToast } from '@/plugins/toast';

import FormInput from '@/components/Form/FormInput.vue';
import FormButton from '@/components/Form/FormButton.vue';

const toast = useToast();
const form = useAjaxForm({
    email: '',
});

form.retrievesCsrf();

form.onSuccess((res) => {
    if (res.data.type === 'success') {
        form.reset();
    }

    toast.publish({
        title: res.data.message,
        color: res.data.type,
        duration: 10000
    });
});
</script>

<template>
    <div class="mt-6 w-full">
        <form @submit.prevent="form.post('/auth/forgot-password')">
            <p class="text-center">Enter your registered email and we'll send you a reset link</p>

            <div class="mt-10">
                <FormInput :form="form" name="email" label="Email" type="email" placeholder="youremail@example.com" />
            </div>

            <FormButton full color="primary" :form="form" class="mt-8">
                Submit
            </FormButton>
        </form>

        <p class="mt-8 text-gray-foreground/60 text-center">
            Sigin Instead? <RouterLink class="text-primary" :to="{ name: 'login' }">Login</RouterLink>
        </p>
    </div>
</template>

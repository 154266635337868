<script setup>
import { computed, ref, nextTick, onMounted, reactive, onUnmounted, onActivated } from 'vue';

const props = defineProps({
    to: {
        default: 'top',
        type: [String, Function],
    },
    zIndex: {
        default: '50',
        type: [String, Function],
    },
    left: {
        type: [String, Function],
    },
    right: {
        type: [String, Function],
    },
    as: {
        default: 'div',
        type: String,
    },
});

const container = ref();
const fixedEl = ref();

const style = reactive({});

const getProp = (prop) => {
    if (typeof props[prop] === 'function') {
        return props[prop]();
    }

    return props[prop];
}

const setStyle = (clone) => {
    style.position = 'fixed';
    const to = getProp('to');
    const left = getProp('left');
    const right = getProp('right');

    if (to === 'top') {
        style.top = clone.offsetTop;
    } else {
        style.bottom = '0';
    }

    if (left) {
        style.left = left;
    }

    if (right) {
        style.right = right;
    }

    style.width = clone.clientWidth + 'px';
    style.zIndex = getProp('zIndex');
}

let clone = null;

const reStyle = () => {
    nextTick(() => setStyle(clone));
};

onMounted(() => {
    clone = fixedEl.value.cloneNode(true);
    container.value.prepend(clone);
    clone.style.opacity = '0';
    clone.setAttribute('aria-hidden', 'true');

    reStyle();

    window.addEventListener('resize', reStyle);
});

onUnmounted(() => {
    window.removeEventListener('resize', reStyle);
});

onActivated(() => {
    reStyle();
});
</script>

<template>
    <component :is="as" ref="container">
        <div ref="fixedEl" :style="style">
            <slot></slot>
        </div>
    </component>
</template>

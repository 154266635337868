<script setup>
import { onMounted } from 'vue';

import { useStore } from '@/store';
import { promptOpened, useNotificationPermission } from '@/composables/permissions/notification';

import Alert from '@/components/Modals/Alert.vue';

const notification = useNotificationPermission();
</script>

<template>
    <div>
        <Alert
            v-if="$store.state.user"
            v-model:open="promptOpened"
            @cancelled="notification.promptCancelled()"
            @confirmed="notification.promptConfirmed()"
            confirm-text="Enable"
            cancel-text="No, I'll do this later"
            title="Enable Device Notifications"
            description="Enable notifications to stay connected and never miss out on important events."
        />
    </div>
</template>

import * as Sentry from "@sentry/vue";
import { createWebHistory } from 'vue-router';

import { createApp } from '@/app';
import Firebase from '@/plugins/firebase';

const { app, store, router } = createApp({
    context: {
        url: window.location.pathname,
        props: window.props,
        app: window.app,
    },
    history: createWebHistory(),
}, true);

Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/corperland\.ng\/api/, /^https:\/\/corperland\.ngrok\.app\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

if ("serviceWorker" in navigator) {

    let controllerChanged = false;

    navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (controllerChanged) return;

        controllerChanged = true;
        window.location.reload();
    });

    navigator.serviceWorker.register('/sw.js', {
        scope: '/',
    }).then((registration) => {
        if (registration.waiting) {
            store.commit('updateAvailable', registration);
        }

        registration.addEventListener('updatefound', () => {
            const newServiceWorker = registration.installing;

            newServiceWorker?.addEventListener('statechange', () => {
                if (newServiceWorker.state !== 'installed') {
                    return;
                }

                if (navigator.serviceWorker.controller) {
                    console.log('New content available');
                    store.commit('updateAvailable', newServiceWorker);
                } else {
                    console.log('Content is cached for offline use.');
                }
            });
        });
    });
}

const matchAndWatchMedia = (media, matches) => {
    const md = window.matchMedia(media);

    if (md.matches) {
        matches();
    }

    md.onchange = (e) => {
        if (e.matches) {
            matches();
        }
    };
}

matchAndWatchMedia('(max-width: 767.9px)', () => store.commit('deviceType', 'mobile'));
matchAndWatchMedia('(min-width: 768px) and (max-width: 1023.9px)', () => store.commit('deviceType', 'tablet'));
matchAndWatchMedia('(min-width: 1024px)', () => store.commit('deviceType', 'desktop'));

app.use(Firebase);
app.mount('#app');

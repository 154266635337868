<script setup>
import IconCameraAdd from '@/components/Icons/CameraAdd.vue';
import IconCancel from '@/components/Icons/Cancel.vue';
import axios from 'axios';
import { computed, watch } from 'vue';
import { ref } from 'vue';

const model = defineModel();

const imageTypes = 'image/jpeg,image/png,image/webp,image/heic';
const videoTypes = 'video/mp4,video/avi,video/mpeg,video/quicktime';

const props = defineProps({
    roundedFull: {
        required: false,
        default: false,
    },
    acceptImage: {
        required: false,
        type: Boolean,
        default: true,
    },
    acceptVideo: {
        required: false,
        type: Boolean,
        default: false,
    },
    ariaLabel: {
        required: false,
        type: String
    },
    size: {
        required: false,
        type: String,
    },
    multiple: {
        required: false,
        type: Boolean,
        default: false,
    },
    max: {
        required: false,
        type: Number,
        default: 8,
    },
    isPrivate: {
        required: false,
        default: false,
        type: Boolean,
    }
});

const getAccept = computed(() => {
    if (!props.acceptImage && !props.acceptVideo) {
        return imageTypes + ',' + videoTypes;
    }

    let types = [];

    if (props.acceptImage) {
        types.push(imageTypes);
    }
    if (props.acceptVideo) {
        types.push(videoTypes);
    }

    return types.join(',');
});

const original = props.multiple ? model.value : (model.value ? [model.value] : []);
const files = ref([]);
original.forEach((file) => {
    files.value.push({
        id: file.id,
        url: file.url,
        uploading: false,
    });
});

const showAddFile = computed(() => {
    if (props.multiple) {
        return files.value.length < props.max;
    }

    return files.value.length < 1;
});

const uploading = computed(() => {
    if (files.value) {
        return files.value.some(file => file.uploading);
    }

    return false;
})

const fileChanged = (e) => {
    for (const value of e.target.files) {
        if (!showAddFile.value) {
            return;
        }

        const newFile = {
            id: Math.random().toString(36).slice(2, 11),
            url: URL.createObjectURL(value),
            uploading: true,
        };
        files.value.push(newFile);
        axios.post('/api/files', {
            file: value,
            is_private: props.isPrivate ? 1 : 0,
        }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
            const data = res.data.data;
            const file = files.value.find(f => f.id === newFile.id);
            if (file) {
                file.id = data.id;
                file.url = data.url;
                file.uploading = false;
            }

            if (props.multiple) {
                model.value.push(res.data.data);
            } else {
                model.value = res.data.data;
            }
        });
    };

    e.target.value = null;
}

const removeFile = (index) => {
    if (props.multiple) {
        files.value.splice(index, 1);
        model.value.splice(index, 1);
    } else {
        files.value = [];
        model.value = null;
    }
}

watch(model, (newVal) => {
    if (!newVal || newVal.length === 0) {
        files.value = [];
    }
});

defineExpose({
    uploading,
});
</script>

<template>
    <div class="w-full">
        <div class="flex gap-2 overflow-x-auto">
            <ul v-if="files.length > 0" class="flex gap-2">
                <li v-for="file, i in files" :key="file.id">
                    <div class="relative h-20 w-20 bg-black bg-opacity-10" :class="[
                        roundedFull ? 'rounded-full' : 'rounded-md'
                    ]">
                        <div class="absolute w-full h-full bg-black/10 left-0 top-0 rounded-[inherit]"></div>
                        <img
                            class="h-full w-full object-cover rounded-[inherit]"
                            :src="file.url"
                        />
                        <button
                            @click="removeFile(i)"
                            type="button"
                            class="flex items-center justify-center bg-black/20 text-gray h-8 w-8 absolute rounded-full top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
                        >
                            <component :is="multiple ? IconCancel : IconCameraAdd" height="22" width="22" />
                        </button>
                    </div>
                </li>
            </ul>
            <div v-if="showAddFile" class="relative h-20 w-20 bg-black bg-opacity-10" :class="[
                roundedFull ? 'rounded-full' : 'rounded-md'
            ]">
                <button
                    @click="$refs.fileInput.click()"
                    type="button"
                    class="flex items-center justify-center bg-black/20 text-gray h-8 w-8 absolute rounded-full top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
                >
                    <IconCameraAdd height="22" width="22" />
                    <input aria-hidden="true" v-on:change="fileChanged" hidden :multiple="multiple" ref="fileInput" :aria-label="ariaLabel" type="file" :accept="getAccept">
                </button>
            </div>
        </div>
        <div class="flex justify-between py-1">
            <span class="text-xs animate-pulse opacity-90" v-if="uploading">Uploading....</span>
            <span v-if="multiple" class="text-xs font-medium flex ml-auto opacity-70 mt-1">{{ files.length }}/{{ max }}</span>
        </div>
    </div>
</template>

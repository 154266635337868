<script setup>
import { onMounted } from "vue";
import { useRouter } from "vue-router";

import { useAjaxForm } from "@/utils/form";
import { collectionV2 } from "@/composables/resource";

const router = useRouter();

const acceptedConversations = collectionV2({
    url: '/api/conversations',
    params: {
        accepted: true,
    },
});

const conversationRequests = collectionV2({
    url: '/api/conversations',
    params: {
        accepted: false,
        with_total: true,
    },
});
</script>

<template>
    <router-view v-slot="{ Component, route }">
        <KeepAlive>
            <component :key="route.path" :is="Component" :baseProps="{
                acceptedConversations,
                conversationRequests,
            }" />
        </KeepAlive>
    </router-view>
</template>


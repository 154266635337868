<script setup>
import { getCurrentInstance } from 'vue';
import { useRouter } from 'vue-router';

import { useAjaxForm } from '@/utils/form';
import { useStore } from '@/store';
import { PostType } from '@/enums';

import { useNotifications } from '@/composables/notifications';

import Avatar from '@/components/Avatar.vue';
import Content from '@/components/Renderer/Content.vue';
import LineClamp from '@/components/LineClamp.vue';

const instance = getCurrentInstance();
const props = defineProps({
    notification: {
        required: true,
        type: Object,
    },
    as: {
        default: 'li',
    }
});

const markAsRead = useAjaxForm();
const store = useStore();
const router = useRouter();
const notifications = useNotifications();

const goToPost = () => {
    if (!props.notification.read) {
        notifications.markAsRead(props.notification.id);
    }

    router.push({
        name: 'posts.show',
        params: {
            id: props.notification.data.id,
        }
    });
}
</script>

<template>
    <component :is="as">
        <button
            :disabled="markAsRead.processing"
            @click="goToPost"
            :class="{
                'bg-primary-light text-primary-light-foreground': !notification.read,
                'bg-white text-white-foreground': notification.read,
                'opacity-60': markAsRead.processing
            }"
            class="relative flex gap-2 py-4 px-4 text-left w-full" type="button">
            <Avatar size="md" class="shrink-0" :src="notification.data.user_avatar" :fallback-from="notification.data.user_name" />
            <div class="flex items-start gap-2 leading-tight w-[calc(100%-44px)]">
                <div class="grow w-[inherit]">
                    <div class="text-sm">
                        <span class="font-semibold">{{ notification.data.user_name }}<span v-if="notification.data.others"> and {{ notification.data.others }} others</span></span> {{ notification.data.type === PostType.QUESTION ? 'asked' : 'posted' }} in <span class="font-semibold">{{ notification.data.channel }}</span>
                    </div>
                    <LineClamp
                        v-if="!notification.data.others"
                        :lines="2"
                        class="text-sm rounded-xl border border-white-foreground/20 mt-2 px-2 py-3">
                        <Content :content="notification.data.type === PostType.QUESTION ? notification.data.title + ' ' + (notification.data.content ?? '') : notification.data.content" />
                    </LineClamp>
                </div>
                <span class="block shrink-0 text-sm text-white-foreground/60">{{ notification.created_at_diff }}</span>
            </div>
            <div v-if="!notification.read" class="bg-primary rounded-full w-2 h-2 absolute left-1 top-1/2 -translate-y-1/2"></div>
        </button>
    </component>
</template>

<script setup>
import { AccordionContent, AccordionHeader, AccordionItem, AccordionRoot, AccordionTrigger } from 'radix-vue';
import { ref } from 'vue';

import IconChevronDown from '@/components/Icons/ChevronDown.vue';

const rules = [
    {
        title: 'Respect and Inclusivity',
        points: [
            'Treat all members with respect, regardless of their background, beliefs, or opinions.',
            'Avoid discriminatory language or behavior that may offend or harm others.',
        ],
    },
    {
        title: 'Positive Engagement',
        points: [
            'Foster a positive and supportive environment for discussions and interactions.',
            'Encourage constructive feedback and meaningful contributions from all members.',
        ],
    },
    {
        title: 'Content Guidelines',
        points: [
            "Post relevant and appropriate content that aligns with the platform's purpose and values.",
            'Refrain from sharing misleading information, spam, or offensive material.',
        ],
    },
    {
        title: 'Privacy and Security',
        points: [
            'Respect the privacy of others and refrain from sharing personal information without consent.',
            'Report any suspicious or inappropriate behavior to the platform administrators.',
        ],
    },
    {
        title: 'Conflict Resolution',
        points: [
            'Resolve conflicts or disagreements respectfully and privately, if possible.',
            'Seek assistance from moderators or administrators if needed to address disputes.',
        ],
    },
    {
        title: 'Compliance with Policies',
        points: [
            "Adhere to the platform's terms of service and community guidelines at all times.",
            'Report any violations or breaches of guidelines to maintain a safe and welcoming community.',
        ],
    },
    {
        title: 'Continuous Improvement',
        points: [
            'Provide feedback and suggestions for improving the platform and community experience.',
            'Collaborate with other members to enhance the overall engagement and value of Corperland.',
        ],
    }
];

const active = ref(rules[0].title);
</script>

<template>
    <AccordionRoot class="mt-3 bg-white rounded-md" type="single" :collapsible="true" v-model:model-value="active">
        <template v-for="rule in rules" :key="rule.title">
            <AccordionItem class="
                overflow-hidden first:mt-0 first:rounded-t last:rounded-b border-t-0 first:border-t border border-white-foreground/20
                focus-within:shadow-white-foreground focus-within:z-10 focus-within:shadow-[0_0_0_2px] focus-within:relative
            " :value="rule.title">
                <AccordionHeader class="flex">
                <AccordionTrigger class="
                    data-[state=open]:border-b data-[state=open]:border-white-foreground/20
                    text-white-foreground data-[state=open]:bg-primary/5 data-[state=open]:text-primary
                    flex h-[45px] flex-1 cursor-default items-center justify-between bg-white px-5
                    leading-none outline-none group">
                    <span>{{ rule.title }}</span>
                    <IconChevronDown height="18" width="18" class="ease-&lsqb;cubic-bezier(0.87,_0,_0.13,_1)&lsqb; transition-transform duration-300 group-data-[state=open]:rotate-180" />
                </AccordionTrigger>
                </AccordionHeader>
                <AccordionContent class="text-white-foreground/90 data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp overflow-hidden">
                    <ul class="px-5 py-4 flex flex-col gap-2">
                        <li v-for="point, i in rule.points" :key="i">- {{ point }}</li>
                    </ul>
                </AccordionContent>
            </AccordionItem>
        </template>
    </AccordionRoot>
</template>

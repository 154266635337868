<script setup>
import { computed, nextTick, onMounted, onUnmounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { elPositionFix } from '@/utils/fixed';

import IconArrowBack from '@/components/Icons/ArrowBack.vue';
import IconCancel from '@/components/Icons/CancelX.vue';
import Input from '@/components/Form/Input.vue';
import UpdateFound from '@/components/UpdateFound.vue';

const props = defineProps({
    withBackButton: {
        default: false,
        type: Boolean,
    },
    backButtonOnlyMobile: {
        default: false,
        type: Boolean,
    },
    searchCollection: {
        type: Object,
        required: false,
    },
});

const route = useRoute();
const router = useRouter();
const searchEl = ref(null);
const search = ref(route.query.search || '');
const container = ref();
let headerPosition = null;

const searchOpen = computed(() => 'search' in route.query);

const closeSearch = () => {
    props.searchCollection.setParams({
        query: undefined,
    });

    props.searchCollection.fetch().then(() => {
        router.back();
    });
};

const openSearch = () => {
    router.push({
        query: {
            ...route.query,
            search: '',
        },
    }).then(() => {
        nextTick(() => {
            searchEl.value.$el.focus();
        });
    });
};

const runSearch = (value) => {
    router.replace({
        query: {
            ...route.query,
            search: value || '',
        },
    }).then(() => {
        props.searchCollection.setParams({
            query: value || undefined,
        });
        props.searchCollection.fetch();
    });
};

onMounted(() => {
    headerPosition = elPositionFix({
        el: container.value,
        style: {
            top: '0px',
            zIndex: 50,
        }
    });
    if (search.value && props.searchCollection) {
        props.searchCollection.setParams({
            query: search.value,
        });
    }

    props.searchCollection?.fetch();
});

onUnmounted(() => {
    if (headerPosition) {
        headerPosition.disconnect();
    }
});

defineExpose({
    openSearch,
});
</script>

<style lang="postcss">
.view-header {
    height: 60px;
    @apply select-none bg-white text-white-foreground px-4 py-3 flex items-center gap-2 shadow-sm border-b border-white-foreground/10 w-full;
}
</style>

<template>
    <div ref="container" class="w-full">
        <div>
            <UpdateFound />
            <header class="view-header">
                <template v-if="searchOpen && searchCollection">
                    <Input
                        ref="searchEl"
                        v-model="search"
                        autofocus="true"
                        type="search"
                        :debounce="200"
                        placeholder="Search for a user"
                        @finished-typing="runSearch" />
                    <button @click="closeSearch" type="button" aria-label="Close search">
                        <IconCancel class="size-7" />
                    </button>
                </template>
                <template v-else>
                    <button class="block text-left" v-if="withBackButton || ($store.state.deviceType === 'mobile' && backButtonOnlyMobile)" @click="$router.back()" type="button" aria-label="Go back">
                        <IconArrowBack width="28" height="28" />
                    </button>
                    <slot></slot>
                </template>
            </header>
        </div>
    </div>
</template>

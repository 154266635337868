<script setup>
import { ToastDescription, ToastProvider, ToastRoot, ToastTitle, ToastViewport } from 'radix-vue';
import IconDanger from './IconDanger.vue';
import IconInfo from './IconInfo.vue';
import IconSuccess from './IconSuccess.vue';
import IconWarning from './IconWarning.vue';

const props = defineProps({
    toasts: {
        required: true,
        type: Array,
    }
});

const getIcon = (color) => {
    const map =  {
        danger: IconDanger,
        info: IconInfo,
        success: IconSuccess,
        warning: IconWarning,
        default: IconInfo,
    }

    return map[color ?? 'default'];
}
</script>

<template>
    <ToastProvider swipeDirection="right" duration="5000">
        <li class="p-0 m-0 list-none" v-for="toast, index in toasts" :key="index">

            <ToastRoot
            :duration="toast.duration ?? 5000"
            :class="{
                'bg-success-light text-success-light-foreground border-success': toast.color == 'success',
                'bg-warning-light text-warning-light-foreground border-warning': toast.color == 'warning',
                'bg-danger-light text-danger-light-foreground border-danger': toast.color == 'danger',
                'bg-info-light text-info-light-foreground border-info': toast.color == 'info',
                'bg-white text-white-foreground': !toast.color,
            }"
            class="
                flex flex-row gap-1
                rounded-md p-4 shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px]
                data-[state=open]:animate-slideIn data-[state=closed]:animate-hide data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=cancel]:translate-x-0 data-[swipe=cancel]:transition-[transform_200ms_ease-out] data-[swipe=end]:animate-swipeOut
            ">
                <component class="shrink-0" :is="getIcon(toast.color)" height="24px" width="24px" />

                <div>
                    <ToastTitle class="flex font-medium">
                        <span>
                            {{ toast.title }}
                        </span>
                    </ToastTitle>
                    <ToastDescription v-if="toast.description">
                        {{ toast.description }}
                    </ToastDescription>
                </div>
            </ToastRoot>
        </li>
        <ToastViewport class="[--viewport-padding:_25px] fixed top-0 right-0 flex flex-col p-[var(--viewport-padding)] gap-[10px] w-[390px] max-w-[100vw] m-0 list-none z-[2147483647] outline-none" />
    </ToastProvider>
</template>

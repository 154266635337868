<script setup>
import { useAjaxForm } from '@/utils/form';
import { ref } from 'vue';
import { useRoute, RouterLink, useRouter } from 'vue-router';

import FormInput from '@/components/Form/FormInput.vue';
import FormButton from '@/components/Form/FormButton.vue';
import { useToast } from '@/plugins/toast';

const toast = useToast();
const loading = ref(true);
const route = useRoute();
const router = useRouter();
const token = route.params.token;

const form = useAjaxForm({
    email: null,
    password: null,
    password_confirmation: null,
    token: route.params.token,
});

form.retrievesCsrf();
form.onSuccess((response) => {
    toast.success(response.data.message);
    router.push({ name: 'login' });
});
</script>

<template>
    <div class="mt-6 w-full">
        <form @submit.prevent="form.post('/auth/reset-password')">
            <p class="text-center">Please enter your new password below to reset your account password.</p>
            <div class="mt-10">
                <FormInput :form="form" name="email" label="Email" type="email" placeholder="youremail@example.com" />
                <FormInput :form="form" name="password" label="New Password" type="password" placeholder="*******" />
                <FormInput :form="form" name="password_confirmation" label="Re-type New Password" type="password" placeholder="******" />
            </div>

            <FormButton full color="primary" :form="form" class="mt-8">
                Reset
            </FormButton>
        </form>

        <p class="mt-8 text-gray-foreground/60 text-center">
            Sigin Instead? <RouterLink class="text-primary" :to="{ name: 'login' }">Login</RouterLink>
        </p>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import BaseModal from '@/components/Modals/BaseModal.vue';
import IconSearch from '@/components/Icons/Search.vue';
import IconCancelLight from '@/components/Icons/CancelLight.vue';
import IconBack from '@/components/Icons/Back.vue';
import ModalTitle from '@/components/Modals/ModalTitle.vue';

const props = defineProps({
    placeholder: {
        required: false,
        type: String,
    },
    type: {
        required: false,
        type: String,
        default: 'post',
    },
    defaultValue: {
        required: false,
        type: String,
    },
});
const searchActive = ref(false);
const input = ref();
const query = ref('');
const router = useRouter();

let delayedSearch;
let lastSearch = '';

const setActive = () => {
    searchActive.value = true;
    setTimeout(() => {
        input.value.value = props.defaultValue ?? '';
        query.value = input.value.value;
        input.value.focus();
        if (input.value.value) {
            input.value.select();
        }
    }, 20);
}

const setInactive = () => {
    clearTimeout(delayedSearch);
    query.value = '';
    searchActive.value = false;
};

const clearInput = () => {
    input.value.value = '';
    query.value = '';
    clearTimeout(delayedSearch);
};

const search = () => {
    if (!query.value) {
        return;
    }

    //
}

const typing = (e) => {
    clearTimeout(delayedSearch);
    query.value = e.target.value.trim();
    const value = query.value;
    if (lastSearch === value) {
        return;
    }

    delayedSearch = setTimeout(function () {
        if (value) {
            //
        }

        lastSearch = value;
    }, 1000);
}

const routeTo = computed(() => {
    return {
        name: `search.${props.type}`,
        query: {
            query: query.value
        }
    }
});
</script>

<template>
    <div>
        <button
            aria-label="Activate search form"
            class="flex items-center justify-center"
            type="button"
            @click="setActive">
            <IconSearch class="size-8" />
        </button>
        <BaseModal :open="searchActive" @update:open="setInactive" animation="from-top" no-backdrop-blur>
            <form class="bg-white text-white-foreground w-screen md:w-[450px] md:min-h-[450px] md:rounded-md" @submit.prevent="router.push(routeTo)">
                <ModalTitle>
                    Search form
                </ModalTitle>

                <div class="w-full h-14 z-10 px-4 py-2 border-b border-white-foreground/10">
                    <div class="bg-gray w-full h-full rounded-full flex flex-row items-center px-2">
                        <button class="block text-left shrink-0" type="button" aria-label="close" @click="searchActive = false">
                            <IconBack
                                height="24"
                                width="24" />
                        </button>
                        <input
                            @input="typing"
                            autofocus="true"
                            ref="input"
                            type="search"
                            class="block grow border-0 ring-0 focus:ring-0 bg-transparent pl-1 pr-0"
                            :placeholder="placeholder" />
                    </div>
                </div>
                <ul class="
                    w-full bg-white rounded-[inherit]
                    *:px-2 *:py-3 *:border-white-foreground/10 *:border-t
                    *:flex *:gap-2 *:items-center">
                    <li v-show="query">
                        <IconSearch class="size-5" />
                        <RouterLink type="submit" class="block w-full text-left" :to="routeTo">
                            Search for <span class="font-semibold">{{ query }}</span>
                        </RouterLink>
                    </li>
                </ul>
            </form>
        </BaseModal>
    </div>
</template>

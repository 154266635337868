<script setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';

import { useLogin } from '@/composables/actions';

import AppButton from '@/components/Button/Button.vue';
import Error from '@/components/Form/Error.vue';
import FormInput from '@/components/Form/FormInput.vue';
import SocialButtons from '@/components/Auth/SocialButtons.vue';

const route = useRoute();
const socialLoading = ref(false);
const login = useLogin({
    email: '',
    password: '',
});

const redirect = route.query.redirect || null;
</script>

<template>
    <div class="mt-6 w-full">
        <SocialButtons @loading="(val) => socialLoading = val" />

        <form @submit.prevent="login.execute(redirect)" class="mt-5">
            <FormInput :form="login.form" name="email" label="Email" type="email" autocomplete="email" />

            <FormInput :form="login.form" name="password" label="Password" type="password" autocomplete="current-password" />

            <Error :value="login.form.errors.credentials" />

            <AppButton rounded-full full color="primary" type="submit" :disabled="login.processing === true || socialLoading === true" class="mt-8" :loading="login.processing || socialLoading">
                Login
            </AppButton>
        </form>
        <RouterLink
                :to="{ name: 'forgot-password' }"
                class="mt-4 block ml-auto text-primary w-fit"
                type="button"
            >
            Forgot password?
        </RouterLink>

        <p class="mt-8 text-gray-foreground/60 text-center">
            Don't have an account? <RouterLink class="text-primary font-semibold" :to="{ name: 'register' }">Register</RouterLink>
        </p>
    </div>
</template>

<script setup>
import AppButton from '@/components/Button/Button.vue';
import { computed } from 'vue';

const props = defineProps({
    form: {
        required: true,
        type: Object,
    },
    formId: String,
    disabledOnClean: {
        required: false,
        type: Boolean,
        default: true,
    },
});

const disabled = computed(() => {
    return !props.form.isValid ||
        props.form.processing ||
        (props.disabledOnClean ? props.form.isClean() : false);
});
</script>

<template>
    <AppButton :disabled="disabled" type="submit" :loading="form.processing" :form="formId">
        <slot />
    </AppButton>
</template>

<script setup>
import { DialogClose } from 'radix-vue';

import BaseModal from '@/components/Modals/BaseModal.vue';
import IconClose from '@/components/Icons/Close.vue';
import ModalTitle from '@/components/Modals/ModalTitle.vue';

defineProps({
    contentClass: {
        type: String,
        default: '',
        required: false,
    },
    bg: {
        type: String,
        default: 'white',
        required: false,
    },
    size: {
        type: String,
        default: 'md',
        required: false,
    },
    full: {
        type: Boolean,
        default: false,
        required: false
    },
    hideClose: {
        required: false,
        type: Boolean,
        default: false,
    },
    withoutPadding: {
        required: false,
        type: Boolean,
        default: false,
    }
});
const colorMap = {
    white: 'bg-white text-white-foreground',
    gray: 'bg-gray text-gray-foreground',
};
</script>

<template>
    <BaseModal>
        <div class="
            max-h-[90vh] md:max-h-[90vh]
            w-screen md:w-[450px]
            overflow-auto relative
            rounded-t-[36px] md:rounded-b-[36px]
            *:bg-[inherit] *:text-[inherit]
        "
        :class="[colorMap[bg], !withoutPadding ? '*:px-4' : '']"
        >
            <ModalTitle show v-if="$slots.header" class="sticky top-0 z-50 w-full first:pt-8">
                <slot name="header"></slot>
            </ModalTitle>
            <div :class="{
                'pb-8 first:pt-8 pt-4': !withoutPadding
            }">
                <slot />
            </div>
        </div>

        <DialogClose
            v-if="!hideClose"
            class="
                z-50 absolute top-8 right-5
                inline-flex h-[25px] w-[25px] appearance-none
                items-center justify-center rounded-full
                focus:shadow-[0_0_0_2px] focus:outline-none
            "
            :class="[colorMap[bg]]"
            aria-label="Close"
            >
            <IconClose />
        </DialogClose>
    </BaseModal>
</template>

<script setup>
import { onMounted } from 'vue';

import { collection } from '@/composables/resource';
import { renderer } from '@/composables/renderer';

import ChannelList from '@/components/Channel/ChannelList.vue';
import Content from '@/views/_partials/Content.vue';
import Header from '@/views/_partials/header/Header.vue';
import HeaderTitle from '@/views/_partials/header/HeaderTitle.vue';
import Pill from '@/components/Pills/Pill.vue';
import PillGroup from '@/components/Pills/PillGroup.vue';
import Search from '@/components/Search.vue';

const myChannels = collection({
    url: '/api/channels',
    filter: {
        view: 'myChannels',
    }
}, 'myChannels');

const topChannels = collection({
    url: '/api/channels',
    filter: {
        view: 'topChannels',
    }
}, 'topChannels');

const dataRenderer = renderer([
    {
        key: 'myChannels',
        label: 'My Channels',
        collection: myChannels,
        onActive: () => myChannels.fetchOnce()
    },
    {
        key: 'topChannels',
        label: 'Top Channels',
        collection: topChannels,
        onActive: () => topChannels.fetchOnce()
    },
]);

onMounted(() => {
    dataRenderer.setActiveView('myChannels');
});
</script>

<template>
    <Content>
        <Header>
            <HeaderTitle>
                Channels
            </HeaderTitle>
            <Search class="ml-auto" :placeholder="`Search for Channels on Corperland`" type="channel" />
        </Header>

        <div class="px-4 py-4">
            <PillGroup>
                <Pill as="button" type="button" @click="dataRenderer.setActiveView(view.key)" v-for="view in dataRenderer.views" :key="view.key" :data-active="dataRenderer.activeView?.key === view.key">
                    {{ view.label }}
                </Pill>
            </PillGroup>

            <template v-for="view in dataRenderer.views" :key="view.key">
                <ChannelList v-if="dataRenderer.activeView?.key === view.key" :collection="view.collection" class="mt-6" />
            </template>
        </div>
    </Content>
</template>

import { computed, reactive, ref } from 'vue';

export const renderer = (views) => {
    views = Array.isArray(views) ? views : [views];

    return reactive({
        activeView: null,
        views,
        setActiveView(key) {
            const view = this.views.filter(view => view.key === key)[0] ?? null;

            if (!view) {
                throw Error(`View [${view}] does not exist`);
            }

            this.activeView = view;

            if ('onActive' in view) {
                this.activeView.onActive();
            }
        }
    });
};

<script setup>
import { onMounted } from 'vue';

import ConversationList from '@/components/Conversation/List.vue';
import Header from '@/views/_partials/header/Header.vue';
import HeaderTitle from '@/views/_partials/header/HeaderTitle.vue';
import IndexBase from '@/views/conversations/ListBase.vue';

const props = defineProps({
    baseProps: Object,
    as: {
        default: 'div',
        required: false,
    }
});

onMounted(() => {
    props.baseProps.conversationRequests.fetchOnce();
});
</script>

<template>
    <component :is="as">
        <Header with-back-button>
            <HeaderTitle>
                Chat requests
            </HeaderTitle>
        </Header>
        <ConversationList :route-replace="$route.name !== 'conversations.requests' && $store.state.deviceType !== 'mobile'" :route-query="{
            referer: 'requests',
        }" :conversations="baseProps.conversationRequests" />
    </component>
</template>

<script setup>
import { getCurrentInstance, ref } from 'vue';

import Error from '@/components/Form/Error.vue';
import Label from '@/components/Form/Label.vue';
import Input from '@/components/Form/Input.vue';

defineEmits(['update:show', 'resend']);

const instance = getCurrentInstance();
const props = defineProps({
    form: {
        required: true,
        type: Object,
    },
    show: {
        required: false,
        type: Boolean,
        default: false,
    },
    hideCancel: {
        required: false,
        type: Boolean,
        default: false,
    }
});

const code = ref('');
const error = ref(null);

props.form.validate(() => {
    if (!props.show) {
        return true;
    }

    return code.value && code.value.length === 6;
});

props.form.beforeSubmit((config, retrying) => {
    if (props.show && !retrying) {
        config.headers['X-Two-Fa-Code'] = code.value;
    } else {
        config.headers['X-Two-Fa-Code'] = undefined;
    }
});

props.form.onSuccess(() => {
    instance.emit('update:show', false);
    error.value = null;
});

props.form.onError((err) => {
    const twoFaRequired = err.response.headers['x-two-fa-required'];

    if (!twoFaRequired) {
        instance.emit('update:show', false);
        return;
    }

    instance.emit('update:show', true);

    error.value = err.response.headers['x-two-fa-error'] ?? error.value;
});

const cancel = () => {
    props.form.reset();
    instance.emit('update:show', false);
}

const resend = () => {
    let stop = false;

    const event = {
        get type() {
            return 'resend';
        },

        stop() {
            stop = true;
        }
    };

    instance.emit('resend', event);

    if (!stop) {
        props.form.retry();
    }
}
</script>

<template>
    <div v-show="show" class="mt-6 first:mt-0">
        <Label>
            One Time Password
        </Label>
        <Input
            v-model="code"
            label="OTP"
            placeholder="XXXXXX"
            type="text"
            inputmode="numeric"
            autocomplete="one-time-code"
        />
        <small class="opacity-70 text-xs block mt-1">An OTP has been sent to your email</small>
        <Error :value="error" />
        <div class="flex mt-1">
            <button v-if="!hideCancel" type="button" @click.prevent="cancel">Cancel</button>
            <span class="grow"></span>

            <button
                @click.prevent="resend"
                class="text-primary"
                type="button"
            >
                Resend Code?
            </button>
        </div>
        <div v-if="$store.state.app.twoFa.sandbox" class="mt-2 italic text-sm">
            Test mode enabled. Use <strong class="text-primary">000000</strong> or any 6 digits to pass.
        </div>
    </div>
</template>

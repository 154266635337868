<script setup>
import { getCurrentInstance } from 'vue';
import { useRouter } from 'vue-router';

import { useAjaxForm } from '@/utils/form';
import { useStore } from '@/store';

import { useNotifications } from '@/composables/notifications';

import Avatar from '@/components/Avatar.vue';
import Content from '@/components/Renderer/Content.vue';
import LineClamp from '@/components/LineClamp.vue';

const instance = getCurrentInstance();
const props = defineProps({
    notification: {
        required: true,
        type: Object,
    },
    as: {
        default: 'li',
    }
});

const markAsRead = useAjaxForm();
const store = useStore();
const router = useRouter();
const notifications = useNotifications();

const goToNotification = () => {
    if (!props.notification.read) {
        notifications.markAsRead(props.notification.id);
    }

    if (!props.notification.data.link) {
        return;
    }

    const url = new URL(props.notification.data.link);

    if (url.origin === window.location.origin) {
        router.push(url.pathname + url.search);
    } else {
        window.location = url.href;
    }
}
</script>

<template>
    <component :is="as">
        <button
            :disabled="markAsRead.processing"
            @click="goToNotification"
            :class="{
                'bg-primary-light text-primary-light-foreground': !notification.read,
                'bg-white text-white-foreground': notification.read,
                'opacity-60': markAsRead.processing
            }"
            class="relative flex gap-2 py-4 px-4 text-left w-full" type="button">
            <Avatar v-if="notification.data.icon" size="md" class="shrink-0" :src="notification.data.icon" />
            <div class="flex items-start gap-2 leading-tight" :class="{
                'w-full': !notification.data.icon,
                'w-[calc(100%-64px)]': notification.data.icon,
            }">
                <div class="grow w-[inherit]">
                    <div class="text-sm">
                        <Content :content="notification.data.title" />
                    </div>
                    <LineClamp
                        v-if="notification.data.body"
                        :lines="2"
                        class="text-sm rounded-xl border border-white-foreground/20 mt-2 px-2 py-3">
                        <Content :content="notification.data.body" />
                    </LineClamp>
                </div>
                <span class="block shrink-0 text-sm text-white-foreground/60">{{ notification.created_at_diff }}</span>
            </div>
            <div v-if="!notification.read" class="bg-primary rounded-full w-2 h-2 absolute left-1 top-1/2 -translate-y-1/2"></div>
        </button>
    </component>
</template>

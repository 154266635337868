
import { inject } from 'vue';

const key = Symbol('config');

const configs = import.meta.env;

const createPlugin = () => {
    return {
        all() {
            return configs;
        },
        get(key) {
            return configs[key];
        }
    };
}

export function useConfig() {
    const config = inject(key);

    if (!config) {
        throw new Error('useConfig() is called without provider.');
    }

    return config;
}

export default {
    get(key) {
        const plugin = createPlugin();

        return plugin.get(key);
    },
    all() {
        const plugin = createPlugin();

        return plugin.all();
    },
    install(app) {
        const plugin = createPlugin();
        app.provide(key, plugin);
    }
}
